import React from 'react';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';

export default function CounterpartiesNavbar() {
    return (
        <>
        <nav className="nav bg-light border-bottom">
            <div className="d-flex">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Добави контрагент
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-submenu">
                        <Link className="dropdown-item dropdown-toggle" to={`#`}>Клиент</Link>
                        <ul className="dropdown-menu">
                            <Link className="dropdown-item" to={`#`}>Клиент</Link>
                            <Link className="dropdown-item" to={`#`}>Дистрибутор</Link>
                            <Link className="dropdown-item" to={`#`}>Офис</Link>
                        </ul>
                    </div>
                    
                    <div className="dropdown-submenu">
                        <Link className="dropdown-item dropdown-toggle" to={`#`}>Доставчк</Link>
                        <ul className="dropdown-menu">
                            <Link className="dropdown-item" to={`#`}>Доставчик</Link>
                            <Link className="dropdown-item" to={`#`}>Производител</Link>
                        </ul>
                    </div>
                    <Link className="dropdown-item dropdown-item-sm dropdown" to="#">Банка</Link>
                    <Link className="dropdown-item dropdown-item-sm dropdown" to="#">Холдинг</Link>
                    <div className="dropdown-submenu">
                        <Link className="dropdown-item dropdown-toggle" to={`#`}>Институция</Link>
                        <ul className="dropdown-menu">
                            <Link className="dropdown-item" to={`#`}>Държавна организация</Link>
                            <Link className="dropdown-item" to={`#`}>Община</Link>
                        </ul>
                    </div>
                    <Link className="dropdown-item dropdown-item-sm dropdown" to="#">Физическо лице</Link>
                    <Link className="dropdown-item dropdown-item-sm dropdown" to="#">Други</Link>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Настройки
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item dropdown-item-sm" to="#">Типове контрагенти</Link>
                    <Link className="dropdown-item dropdown-item-sm" to="/counterparties/statuses">Статуси контрагенти</Link>
                    <Link className="dropdown-item dropdown-item-sm" to="/counterparties/activitytypes">Типове събития</Link>
                    <Link className="dropdown-item dropdown-item-sm" to="/counterparties/statuses/tasks">Статус задачи</Link>
                    </div>
                </li>
            </div>
            <div className="ml-auto mr-2">
                <form className="d-flex mt-1">
                    
                <div className="form-group d-flex mr-2">
                    <select className="form-control">
                        <option disabled>Тип контрагент</option>
                        <option value="1" selected>Всички</option>
                        <option value="2">Клиенти</option>
                        <option value="3">Доставчици</option>
                        <option value="4">Банки</option>
                        <option value="5">Холдинги</option>
                        <option value="6">Институции</option>
                        <option value="7">Физически лица</option>
                        <option value="8">Други</option>
                    </select>
                </div>
                
                <div class="form-group d-flex">
                    <input type="text" placeholder="Бързо търсене" className="form-control mr-2" />
                    <button data-tip data-for="showExtendedFilterTooltip" type="button" className="btn btn-transparent mr-2">
                        <i className="fas fa-filter text-primary" />
                    </button>
                    <ReactTooltip id="showExtendedFilterTooltip" place="bottom" type="info">
                    <span>Подробен филтър</span>
                    </ReactTooltip>
                    <select className="form-control mr-2">
                        <option disabled>Изберете дружество</option>
                        <option value="1" selected>LINBRIX</option>
                        <option value="2">LINBRIX Инженеринг</option>
                    </select>
                </div>

                
                </form>
            </div>
        </nav>        
        </>);
}