import React from 'react';
import ReactTooltip from 'react-tooltip';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { colourStyles } from './ReactSelectStyles';
const animatedComponents = makeAnimated();

const ReactSelectOptions = [
    { value: 'project1', label: 'Проект 1' },
    { value: 'project2', label: 'Проект 2' },
    { value: 'project3', label: 'Проект 3' },
];

export default function Buyer(props) {
    return (
        <form>
            <div className="form-group row">
                <div className="col-sm-12">
                    <button className="col-sm-12 font-weight-bold btn btn-light text-left">КУПУВАЧ</button>
                </div>
                {/* <div className="col-sm-6">
                    <div className="form-check mt-2 float-right">
                    <input className="form-check-input" type="checkbox" id="addNewInvoiceBuyer" />
                    <label className="form-check-label" htmlFor="addNewInvoiceBuyer">
                        Добави като нов
                    </label>
                </div>
                </div> */}
            </div>
            <div className="form-group row">
                <div className="col-sm-12">
                    <input type="text" className="form-control" placeholder="Наименование на фирмата на клиента" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">МОЛ</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" placeholder="МОЛ" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Държава</label>
                <div className="col-sm-4">
                    <select className="form-control">
                        <option>България</option>
                    </select>
                </div>
                <label className="col-sm-2 col-form-label">Седалище</label>
                <div className="col-sm-4">
                    <input type="text" className="form-control" />
                </div>
            </div>
            {/* <div className="form-group row">
                <label className="col-sm-2 col-form-label">Адрес</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" />
                </div>
            </div> */}
            <div className="form-group row">
                <label data-tip data-for="showTIDTooltip" className="col-sm-2 col-form-label">ЕИК</label>
                <ReactTooltip id="showTIDTooltip" place="bottom" type="info">
                    <span>Идентификационен номер</span>
                </ReactTooltip>
                <div className="col-sm-4">
                    <input type="text" className="form-control" placeholder="ЕИК" />
                </div>
                <label data-tip data-for="showVATNumberTooltip" className="col-sm-2 col-form-label">ЗДДС/№</label>
                <ReactTooltip id="showVATNumberTooltip" place="bottom" type="info">
                    <span>Идент. ном. по ЗДДС</span>
                </ReactTooltip>
                <div className="col-sm-4">
                    <input type="text" className="form-control" placeholder="ДДС номер" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label"><a href="" onClick={e => {
                        e.preventDefault();
                        alert('Modal: Проект.');
                    }}>Проект</a></label>
                <div className="col-sm-10">
                <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={ReactSelectOptions}
                        styles={colourStyles}
                        placeholder={'Моля, изберете проект!'}
                    />
                </div>
            </div>
        </form>
    );
}