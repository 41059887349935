import React from 'react';
import Modal from 'react-modal';

export default function DocumentCountersModal(props) {    
    if (props.close, props.value, props.open) {
        return (
            <Modal
              isOpen={props.value}
              onRequestClose={props.close}
              className="custom-modal-sm border"
              overlayClassName="custom-modal-overlay"
              contentLabel="Document Counters"
            >

            <div className="container-fluid bg-light border-bottom">
                <div className="row">
                    <div className="col-9">
                    <h4 className="mt-2">Настройки на броячите на документи</h4>
                    </div>
                    <div className="col-3">
                        <button type="button" className="close p-2" onClick={props.close}> 
                            <span>&times;</span> 
                        </button>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-3">
                <div className="row">
                    <div className="col-12">
                    <form className="py-2">
                        <div className="form-group row">
                            <label className="col-sm-6 col-form-label">Фактура, ДИ. КИ. (последен номер)</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" />
                            </div>
                            <div className="col-sm-3">
                                <input type="button" className="btn btn-primary w-100" value="Запази" />
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
            
            </Modal>
        );
    } else {
        return (<></>);
    }
}