import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <Link className="navbar-brand" to="/"><img src={`http://${process.env.REACT_APP_ROOT}/images/logo-white.png`} height="20" alt="" /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarGeneral" aria-controls="navbarGeneral" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarGeneral">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item  dropdown mr-2">
                        <Link className="nav-link text-white dropdown-toggle" to="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-sitemap mr-1"></i> Модули
                    </Link>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to="/accounting"><i className="fas fa-coins mr-1"></i> Фактуриране и документи</Link>
                            <Link className="dropdown-item" to="/"><i className="fas fa-handshake mr-1"></i> Търговска дейност</Link>
                            <Link className="dropdown-item" to="/"><i className="fas fa-project-diagram mr-1"></i> Управление на проекти</Link>
                            <Link className="dropdown-item disabled" to="/"><i className="fas fa-calculator mr-1"></i> Счетоводство и финанси</Link>
                            <Link className="dropdown-item disabled" to="/"><i className="fas fa-warehouse mr-1"></i> Склад и логистика</Link>
                            <Link className="dropdown-item disabled" to="/"><i className="fas fa-people-carry mr-1"></i> Персонал и услуги</Link>
                            <Link className="dropdown-item disabled" to="/"><i className="fas fa-thumbtack mr-1"></i> Задачи и графици</Link>
                        </div>
                    </li>
                    <li className="nav-item  mr-2">
                        <Link className="nav-link text-white" to="/counterparties"><i className="fas fa-user-tie mr-1"></i> Контрагенти</Link>
                    </li>
                    <li className="nav-item  mr-2">
                        <Link className="nav-link text-white" to="/"><i className="fas fa-stream mr-1"></i> Номенклатури</Link>
                    </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown mr-1">
                        <Link className="btn text-white dropdown-toggle" to="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-bell"></i> <span className="badge badge-primary    ">0</span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <Link to="/" className="dropdown-item"> Нямате активни аларми. </Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown mr-1">
                        <Link className="btn text-white dropdown-toggle" to="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-envelope"></i> <span className="badge badge-primary    ">0</span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <Link to="/" className="dropdown-item"> Нямате активни известия. </Link>
                        </div>
                    </li>

                    <li className="nav-item dropdown bg-white rounded-sm">
                        <Link className="btn text-primary dropdown-toggle" to="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-building mr-1"></i> Моля изберете дружество 
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to="/"><i className="fas fa-building mr-1"></i> ИНВЕСТОР И КО ЕООД</Link>
                            <Link className="dropdown-item" to="/"><i className="fas fa-building mr-1"></i> ПМ СОФТ ООД</Link>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <Link className="btn text-white dropdown-toggle" to="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-user mr-1"></i> Стефан Димов
                    </Link>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to="/"><i className="fas fa-hands-helping mr-1"></i> Вътрешни дружества</Link>
                            <Link className="dropdown-item" to="/"><i className="fas fa-users mr-1"></i> Потребители</Link>
                            <Link className="dropdown-item" to="/"><i className="fas fa-cog mr-1"></i> Настройки</Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" to="/"><i className="fas fa-user-cog mr-1"></i> Профил </Link>
                            <Link className="dropdown-item" to="/"><i className="fas fa-sign-out-alt mr-1"></i> Изход </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}