import React from 'react';
import Modal from 'react-modal';

export default function InvoiceModal(props) {

    if (props.close, props.value, props.open) {
        return (
            <Modal
                isOpen={props.value}
                onRequestClose={props.close}
                className="custom-modal-sm border"
                overlayClassName="custom-modal-overlay"
                contentLabel="Add new sales invoice"
            >

                <div className="container-fluid bg-light border-bottom">
                    <div className="row">
                        <div className="col-9">
                            <h4 className="mt-2">Вид на документа</h4>
                        </div>
                        <div className="col-3">
                            <button type="button" className="close p-2" onClick={props.close}>
                                <span>&times;</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row py-2 bg-light">
                        <form className="col-sm-12">
                            <div className="form-group row">
                                <div className="form-group col-sm-3"></div>
                                <div className="form-group col-sm-6">
                                    <label className="col-form-label col-md-12 mx-0 px-0"><a href="" onClick={e => {
                                        e.preventDefault();
                                        alert('Modal: Въвеждане на номенклатури');
                                    }}>Документът е за</a></label>
                                    <div>
                                        <select defaultValue={props.state.documentType} onChange={e => props.setState({...props.state, documentType: e.target.value})} className="form-control">
                                            <option disabled={true}>Покупка</option>
                                            <option>Продажба</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group col-sm-3"></div>
                                <div className="form-group col-sm-6">
                                    <label className="col-form-label col-md-12 mx-0 px-0"><a href="" onClick={e => {
                                        e.preventDefault();
                                        alert('Modal: Въвеждане на номенклатури');
                                    }}>Име на документа</a></label>
                                    <div>
                                        <select defaultValue={props.state.documentFor} onChange={e => props.setState({...props.state, documentFor: e.target.value})} className="form-control">
                                            <option>Проформа фактура</option>
                                            <option>Фактура</option>
                                            <option>Кредитно известие</option>
                                            <option>Дебитно известие</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group col-sm-6">
                                    <label className="col-form-label col-md-12 mx-0 px-0"><a href="" onClick={e => {
                                        e.preventDefault();
                                        alert('Modal: Въвеждане на номенклатури');
                                    }}>За</a></label>
                                    <div>
                                        <select defaultValue={props.state.documentAssetType} onChange={e => props.setState({...props.state, documentAssetType: e.target.value})} className="form-control">
                                            <option disabled>Избери</option>
                                            <option>ДМА</option>
                                            <option>ДНА</option>
                                            <option>Материал</option>
                                            <option>Продукция</option>
                                            <option>Стока</option>
                                            <option>Услуга</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row mt-2">
                                <div className="form-group col-sm-12">
                                <label className="col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Въвеждане на номенклатури');
                                }}>Вид 2 на документа</a></label>
                                    <select className="form-control">
                                        <option>Фактура</option>
                                        <option>Митническа декларация</option>
                                        <option>Протокол или друг документ</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row mt-2">
                                <div className="form-group col-12 d-flex justify-content-end">
                                    <button type="button" className="btn btn-danger mr-2" onClick={props.close}>Отказ</button>
                                    <button type="button" className="btn btn-primary" onClick={props.close}>Потвърди</button>
                                </div>
                            </div>
                    </form>
                    </div>
                </div>
            </ Modal>
        );
    }
}