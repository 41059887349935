import React from 'react';
import { Switch, Route } from "react-router-dom";

// import Home from '../components/core/Home';
import Root from '../components/Root'
import Accounting from '../components/core/accounting/Accounting'
import AccountingChart from '../components/core/accounting/AccountingChart';
import Counterparties from '../components/core/counterparties/Counterparties';
import CounterpartyStatuses from '../components/core/counterparties/CounterpartyStatuses';
import CounterpartyStatusesTasks from '../components/core/counterparties/CounterpartyStatusesTasks';
import ActivityTypes from '../components/ActivityTypes';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={Root} />
            <Route exact path="/accounting" component={Accounting} />
            <Route exact path="/accounting/chart" component={AccountingChart} />
            <Route exact path="/counterparties" component={Counterparties} />
            <Route exact path="/counterparties/statuses" component={CounterpartyStatuses} />
            <Route exact path="/counterparties/statuses/tasks" component={CounterpartyStatusesTasks} />
            <Route exact path="/counterparties/activitytypes" component={ActivityTypes} />
        </Switch>
    );
}