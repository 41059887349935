import React from 'react';

export default function InvoiceTable(props) {
    // Invoice table data should be requested from API
    const [invoiceTableData, setinvoiceTableData] = React.useState([
        {
            id: 0,
            type: 'M',
            dealSubject: '',
            articleId: '',
            measurementType: '',
            requestedQuantity: 0,
            quantity: 0,
            singlePrice: 0,
            currencyRate: 1,
            totalPrice: 0
        }
    ]);
    
    const [totalTaxBaseAmount, setTotalTaxBaseAmount] = React.useState(0);
    const [totalVAT, setTotalVAT] = React.useState(0);

    React.useEffect(() => {
        let totalTaxBaseAmountValue = 0;
        for (let i = 0; i < invoiceTableData.length; i++) {
            let newState = invoiceTableData;
            for (const key in invoiceTableData) {
                newState[key]['id'] = Number(key) + 1;
                newState[key]['singlePrice'] = parseFloat(newState[key]['singlePrice']);
                newState[key]['totalPrice'] = parseFloat(newState[key]['totalPrice']).toFixed(2);
            }
            totalTaxBaseAmountValue = parseFloat(parseFloat(totalTaxBaseAmountValue) + parseFloat(newState[i]['totalPrice'])).toFixed(2);
        }
        
        setTotalTaxBaseAmount(totalTaxBaseAmountValue);
        if (props.defaultValues.taxObligations === 'Облагаема сделка') {
            setTotalVAT(parseFloat(totalTaxBaseAmountValue * 0.2).toFixed(2));
        }
        if (props.defaultValues.taxObligations === 'Освободена сделка') {
            setTotalVAT(totalTaxBaseAmountValue * 0);
        }
    }, [invoiceTableData, props.defaultValues]);

    const mainAddTableRow = (e, id) => {
        // Insert new element after a given table row
        let newState = invoiceTableData;
        let newTableRow = {
            id: 0,
            type: 'M',
            dealSubject: '',
            articleId: '',
            measurementType: '',
            requestedQuantity: 0,
            quantity: 0,
            singlePrice: 0,
            currencyRate: 1,
            totalPrice: 0
        };
        newState.splice(id, 0, newTableRow);
        setinvoiceTableData([...newState]);
    }

    const addTableRowSelect = (e, id) => {
        e.preventDefault();
        mainAddTableRow(e, id);
    }

    const addTableRow = (e, id) => {
        if (e.key === 'Enter') {
            mainAddTableRow(e, id);
        }
    }

    const deleteTableRow = (e, id) => {
        e.preventDefault();
        // Initiate new state
        let newState = invoiceTableData;

        // Pop the element out of the array
        newState.splice(`${id - 1}`, 1);

        // Destructuring inside the array is required in order for the component
        // to render correctly
        setinvoiceTableData([...newState]);
    }

    const setTableData = (id, value, field) => {
        // Initiate new state
        let newState = invoiceTableData;
        // Re-render on change of field value
        newState[`${id - 1}`][field] = value;
        // Make sure the quantity and singlePrice fields are number
        // type fields for the calculations to be correct
        let quantity = Number(newState[`${id - 1}`]['quantity']);
        let singlePrice = Number(newState[`${id - 1}`]['singlePrice']);
        if (typeof(quantity) === 'number' && typeof(singlePrice) === 'number') {
            newState[`${id - 1}`]['totalPrice'] = Number(quantity * singlePrice);
        }
        // Destructuring inside the array is required in order for the component
        // to render correctly
        setinvoiceTableData([...newState]);
    }

    const renderInvoiceTable = invoiceTableData.map(item => (<tr>
        <th scope="row">
            <i className="fas fa-arrows-alt" />
            <div className="btn-group m-0 p-0">
                <button type="button" className="btn p-0" style={{ 'lineHeight': '0' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-cog text-primary mb-1" />
                </button>
                <div className="dropdown-menu">
                    <a className="dropdown-item" href="" onClick={e => addTableRowSelect(e, item.id)}>Нов ред</a>
                    <a className="dropdown-item" href="">Копиране</a>
                    <a className="dropdown-item text-danger" href="" onClick={e => deleteTableRow(e, item.id)}>Изтриване</a>
                </div>
            </div>
            {item.id}
        </th>
        <td>
            <select
                onKeyDown={(e) => addTableRow(e, item.id)}
                className="w-100 height-15 border-0"
                >
                <option>M</option>
                <option>D</option>
                <option>U</option>
                <option>P</option>
                <option>I</option>
                <option>F</option>
            </select>
        </td>
        <td>
            <input
                type="text"
                className="form-control height-15"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'articleId')}
                value={item.articleId} />
        </td>
        <td>
            <input
                type="text"
                className="form-control height-15"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'dealSubject')}
                value={item.dealSubject} />
        </td>
        <td>
            <select
                className="form-control height-15"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'measurementType')}
                value={item.measurementType}>
                <option>бр.</option>
                <option>м.</option>
                <option>м2.</option>
                <option>м3.</option>
                <option>кг.</option>
                <option>тон</option>
                <option>л.</option>
                <option>км.</option>
                <option>ч.</option>
            </select>
        </td>
        <td>
            <input
                type="number"
                className="form-control height-15"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'requestedQuantity')}
                value={item.requestedQuantity} />
        </td>
        <td>
            <input
                type="number"
                step="0.01"
                className="form-control height-15"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'quantity')}
                value={parseFloat(item.quantity)} />
        </td>
        <td>
            <input
                type="number"
                step="0.01"
                className="form-control height-15" onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'singlePrice')}
                value={parseFloat(item.singlePrice)} />
        </td>
        <td>
            <input
                type="number"
                step="0.000001"
                className="form-control height-15"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'currencyRate')}
                value={parseFloat(item.currencyRate)} />
        </td>
        <td>
            <input
                type="text"
                step="0.01"
                className="form-control height-15 text-right"
                onKeyDown={(e) => addTableRow(e, item.id)}
                onChange={e => setTableData(item.id, e.target.value, 'totalPrice')}
                value={parseFloat(item.totalPrice).toFixed(2)} />
        </td>
    </tr>));

    return (
        <>
        <table className="table table-bordered mt-2">
            <thead className="thead-light">
                <tr>
                    <th scope="col" style={{'width': '44px'}}>#</th>
                    <th scope="col" style={{'width': '44px'}}><a href="" onClick={e => {e.preventDefault(); alert('Modal: Добавяне на вид.')}}>Вид</a></th>
                    <th scope="col" style={{'width': '120px'}}>код на артикул</th>
                    <th scope="col">Основание и предмет на сделката</th>
                    <th scope="col"style={{'width': '60px'}}><a 
                    href=""
                    onClick={e => {
                        e.preventDefault();
                        alert('Modal: Добавяне на вид мярка.');
                        }}>мярка</a></th>
                    <th scope="col" style={{'width': '100px'}}>поискано</th>
                    <th scope="col" style={{'width': '100px'}}>количество</th>
                    <th scope="col" style={{'width': '100px'}}>ед. цена</th>
                    <th scope="col" style={{'width': '100px'}}>курс</th>
                    <th scope="col" style={{'width': '200px'}}>стойност</th>
                </tr>
            </thead>
            <tbody>
                {renderInvoiceTable}
            </tbody>
        </table>
        <table className="table table-bordered mt-2">
            <tbody>
            <tr>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0 text-right" style={{'width': '200px'}}>Данъчна основа</td>
                    <td className="text-right" style={{'width': '200px'}}><span className="mr-2">{totalTaxBaseAmount}</span></td>
                </tr>
                <tr>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0 text-right" style={{'width': '200px'}}>Сума ДДС</td>
                    <td className="text-right" style={{'width': '200px'}}><span className="mr-2">{totalVAT}</span></td>
                </tr>
                <tr>
                    
                    <td className="border-0" style={{'width': '120px'}}>
                        <label className="col-form-label ml-2"><a href="" onClick={e => {
                            e.preventDefault();
                            alert('Modal: Основание по ЗДДС.');
                        }}>Основание по ЗДДС</a></label>
                    </td>
                    <td className="border-0" style={{'width': '200px'}}>
                        <select defaultValue={props.defaultValues.taxObligations} onChange={e => props.setDefaultValues({...props.defaultValues, taxObligations: e.target.value})} className="form-control">
                            <option>Облагаема сделка</option>
                            <option>Освободена сделка</option>
                            <option>Износ</option>
                            <option>Други</option>
                            <option>Не се включва в регистъра</option>
                            <option>Вътрешен оборот</option>
                            <option>Друг вид</option>
                        </select>
                    </td>
                    <td className="border-0" style={{'width': '120px'}}>
                        <input className="form-check-input ml-2" type="checkbox" id="taxationBaseCheckbox" />
                        <label className="form-check-label ml-4" htmlFor="taxationBaseCheckbox">
                            Разл. дан. основа
                        </label>
                    </td>
                    <td className="border-0" style={{'width': '120px'}}>
                        <input className="form-check-input ml-2" type="checkbox" id="addNewInvoiceAdditive" />
                        <label className="form-check-label ml-4" htmlFor="addNewInvoiceAdditive">
                            Начисление
                        </label>
                    </td>
                    <td className="border-0" style={{'width': '120px'}}>
                        <input className="form-check-input ml-2" type="checkbox" id="addNewInvoiceExcise" />
                        <label className="form-check-label ml-4" htmlFor="addNewInvoiceExcise">
                            Акциз
                        </label>
                    </td>
                    <td className="border-0 text-right font-weight-bold" style={{'width': '200px'}}>Сума за плащане</td>
                    <td className="font-weight-bold text-right" style={{'width': '200px'}}><span className="mr-2">{parseFloat(parseFloat(totalVAT) + parseFloat(totalTaxBaseAmount)).toFixed(2)}</span></td>
                </tr>
            </tbody>
        </table>
        </>
    );
}