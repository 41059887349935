import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from './misc/Loader';

export default function Root() {
    const [loading, setLoading] = useState(false);
    if (loading === true) {
        return (<Loader />);
    } else {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-4" style={{ 'margin': '20vh auto 3vh' }}>
                        <Link to="/">
                            <img src={`http://${process.env.REACT_APP_ROOT}/images/logo-blue.png`} style={{ display: "block", height: "100px", width: "auto", margin: "0 auto" }} alt="" />
                        </Link>
                        <h5 className="text-center text-primary font-weight-bold font-italic">
                            "Opportunities multiply as they are seized" /Sun Tzu/
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row d-flex justify-content-center">
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2" to="/accounting" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-coins" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Фактуриране и документи</span>
                            </Link>
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-handshake" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Търговска <br /> дейност</span>
                            </Link>
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-project-diagram" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Управление <br /> на проекти</span>
                            </Link>
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2 disabled" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-calculator" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Счетоводство <br /> и финанси</span>
                            </Link>
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2 disabled" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-warehouse" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Склад <br /> и логистика</span>
                            </Link>
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2 disabled" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-people-carry" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Персонал <br /> и услуги</span>
                            </Link>
                            <Link className="btn btn-primary text-center rounded-sm text-white p-3 ml-2 disabled" style={{ 'width': '8rem', 'height': '5rem', 'cursor': 'pointer' }}>
                                <i className="d-block fas fa-thumbtack" style={{ 'fontSize': '3rem' }}></i>
                                <span className="d-block text-primary font-weight-bold mt-4">Задачи <br /> и графици</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}