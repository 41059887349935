import React from 'react';
import AccountingNavbar from './nav/AccountingNavbar';
import Breadcrumbs from '../../nav/Breadcrumbs';
import { Link } from 'react-router-dom';

export default function Accounting() {
    let breadcrumbs = [
        {
            address: '/',
            label: 'LINBRIX',
            active: false,
        },
        {
            address: '/accounting',
            label: 'Фактуриране и документи',
            active: false,
        },
        {
            address: '/accounting/income',
            label: 'Приходи',
            active: true,
        }
    ];

    return (
        <>
            {/* <Breadcrumbs data={ breadcrumbs } /> */}

            <nav className="nav bg-light border-bottom">
                <div className="d-flex">
                    <li className="nav-item bg-info">
                        <Link className="nav-link text-white font-weight-bold" to={`#`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-coins mr-1"></i> ФАКТУРИРАНЕ И ДОКУМЕНТИ
                    </Link>
                    </li>
                    <li className="nav-item font-weight-bold">
                        <Link className="nav-link" to={`#`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Приходи
                    </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Разходи
                    </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Други документи
                    </Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Настройки
                    </Link>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <button className="dropdown-item dropdown-item-sm">Броячи на документи</button>
                        </div>
                    </li>
                </div>
            </nav>

            <AccountingNavbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="float-left">
                            <div className="my-1">
                                <button className="btn btn-light mr-1 text-primary"><i className="fas fa-copy" /></button>
                                <button className="btn btn-light mr-1 text-primary"><i className="fas fa-print" /></button>
                                <button className="btn btn-light mr-1 text-primary"><i className="fas fa-trash" /></button>
                            </div>
                        </div>
                        <div className="float-right">
                            <form className="form-inline my-1">
                                <div className="form-group row mr-0">
                                    <label htmlFor="tableYearSelect" className="col-form-label mr-2">Визуализиран период:</label>
                                    <select className="custom-select" id="tableYearSelect">
                                        <option disabled>Изберете период</option>
                                        <option value="1" selected>Всички</option>
                                        <option value="2">2020 год.</option>
                                        <option value="4">Текущ мес.</option>
                                        <option value="5">Предх. мес.</option>
                                    </select>
                                </div>
                            </form>
                        </div>

                        <table className="table table-hover table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="text-center" style={{ 'width': '22px' }}><input type="checkbox" /></th>
                                    <th scope="col" style={{ 'width': '22px' }}></th>
                                    <th scope="col">Име на документа</th>
                                    <th scope="col">Номер</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col">Стойност</th>
                                    <th scope="col">Валута</th>
                                    <th scope="col">Контрагент</th>
                                    <th scope="col">Булстат</th>
                                    <th scope="col">МОЛ</th>
                                    <th scope="col">Папка</th>
                                    <th scope="col">Номер</th>
                                    <th scope="col">Док. е за:</th>
                                    <th scope="col">Вид на стоката</th>
                                    <th scope="col">Други характеристики</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" className="text-center"><input type="checkbox" /></th>
                                    <th scope="row" className="text-center">
                                        <div className="btn-group m-0 p-0">
                                            <button type="button" className="btn p-0" style={{ 'lineHeight': '0' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-cog text-primary mb-1" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" href="#">Преглед</a>
                                                <a className="dropdown-item" href="#">Редакция</a>
                                                <a className="dropdown-item" href="#">Копиране</a>
                                                <a className="dropdown-item" href="#">Анулиране</a>
                                                <a className="dropdown-item text-danger" href="#">Изтриване</a>
                                            </div>
                                        </div>
                                    </th>
                                    <td>Фактура <div className="bg-danger rounded-sm" style={{ 'width': '.6rem', 'height': '.6rem', 'display': 'inline-block', 'transform': 'translateY(.1rem)' }}></div></td>
                                    <td>0000000589</td>
                                    <td>12.03.2020</td>
                                    <td>444.24</td>
                                    <td>BGN</td>
                                    <td>ПМ СОФТ ООД</td>
                                    <td>204407892</td>
                                    <td>Диан Димов</td>
                                    <td>/sys/invoice</td>
                                    <td>17</td>
                                    <td>Покупка</td>
                                    <td>Услуга</td>
                                    <td>Няма</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="text-center"><input type="checkbox" /></th>
                                    <th scope="row" className="text-center">
                                        <div className="btn-group m-0 p-0">
                                            <button type="button" className="btn p-0" style={{ 'lineHeight': '0' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-cog text-primary mb-1" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" href="#">Преглед</a>
                                                <a className="dropdown-item" href="#">Редакция</a>
                                                <a className="dropdown-item" href="#">Копиране</a>
                                                <a className="dropdown-item" href="#">Анулиране</a>
                                                <a className="dropdown-item text-danger" href="#">Изтриване</a>
                                            </div>
                                        </div>
                                    </th>
                                    <td>Фактура <div className="bg-warning rounded-sm" style={{ 'width': '.6rem', 'height': '.6rem', 'display': 'inline-block', 'transform': 'translateY(.1rem)' }}></div></td>
                                    <td>0000000590</td>
                                    <td>12.03.2020</td>
                                    <td>444.24</td>
                                    <td>BGN</td>
                                    <td>ПМ СОФТ ООД</td>
                                    <td>204407892</td>
                                    <td>Диан Димов</td>
                                    <td>/sys/invoice</td>
                                    <td>19</td>
                                    <td>Продажба</td>
                                    <td>Стока</td>
                                    <td>Няма</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="text-center"><input type="checkbox" /></th>
                                    <th scope="row" className="text-center">
                                        <div className="btn-group m-0 p-0">
                                            <button type="button" className="btn p-0" style={{ 'lineHeight': '0' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-cog text-primary mb-1" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" href="#">Преглед</a>
                                                <a className="dropdown-item" href="#">Редакция</a>
                                                <a className="dropdown-item" href="#">Копиране</a>
                                                <a className="dropdown-item" href="#">Анулиране</a>
                                                <a className="dropdown-item text-danger" href="#">Изтриване</a>
                                            </div>
                                        </div>
                                    </th>
                                    <td>Фактура <div className="bg-success rounded-sm" style={{ 'width': '.6rem', 'height': '.6rem', 'display': 'inline-block', 'transform': 'translateY(.1rem)' }}></div></td>
                                    <td>0000000591</td>
                                    <td>12.03.2020</td>
                                    <td>444.24</td>
                                    <td>BGN</td>
                                    <td>ПМ СОФТ ООД</td>
                                    <td>204407892</td>
                                    <td>Диан Димов</td>
                                    <td>/sys/invoice</td>
                                    <td>21</td>
                                    <td>Продажба</td>
                                    <td>Стока</td>
                                    <td>Няма</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}