import React from 'react';

export default function Loader() {
    return (
        <div className="container-fluid">
            <div className="col-12 text-center">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}