import React from 'react';
import Modal from 'react-modal';

export default function AddUserModal(props) {

    if (props.close, props.value, props.open) {
        return (
            <Modal
                isOpen={props.value}
                onRequestClose={props.close}
                className="custom-modal-sm border"
                overlayClassName="custom-modal-overlay"
                contentLabel="Add new user to team"
            >

                <div className="container-fluid bg-light border-bottom">
                    <div className="row">
                        <div className="col-9">
                            <h4 className="mt-2">Добавяне на потребител с прва по документа</h4>
                        </div>
                        <div className="col-3">
                            <button type="button" className="close p-2" onClick={props.close}>
                                <span>&times;</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row py-2 bg-light">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <label>Потребителят по отношение на системата е</label>
                                    <select className="form-control">
                                    <option>Вътрешен потребител</option>
                                    <option>Външен потребител</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Име на потребителя</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>E-mail адрес</label>
                                    <input type="email" className="form-control" />
                                </div>
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label>Фирма</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="col-6">
                                        <label>Офис</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <button type="submit" className="btn btn-primary float-right">Добави потребител</button>
                                    <button type="button" onClick={props.close} className="btn btn-danger float-right mr-2">Отказ</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ Modal>
        );
    }
}