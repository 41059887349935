const generalStyles = {
    height: '26px',
    lineHeight: '26px',
    minHeight: '26px',
    margin: 0,
    padding: 0
}

export const colourStyles = {
    control: (styles) => {
        return {
          ...styles,
          ...generalStyles,
        };
      },
    multiValue: (styles) => {
      return {
        ...styles,
        ...generalStyles,
        backgroundColor: 'none',
      };
    },
    multiValueLabel: (styles) => {
        return {
            ...styles,
            ...generalStyles,
            backgroundColor: 'none',
        };
    },
    multiValueRemove: (styles) => {
        return {
            ...styles,
            ...generalStyles,
            backgroundColor: 'none',
            padding: '0px 5px'
        };
    },
    container: (styles) => {
        return {
          ...styles,
          ...generalStyles,
        };
    },
    dropdownIndicator: (styles) => {
        return {
          ...styles,
          ...generalStyles,
          paddingTop: '3px',
        };
    },
    clearIndicator: (styles) => {
        return {
          ...styles,
          ...generalStyles,
          paddingTop: '3px',
        };
    },
    indicatorSeparator: (styles) => {
        return {
          ...styles,
          ...generalStyles,
          display: 'none'
        };
    },
    input: (styles) => {
        return {
          ...styles,
          ...generalStyles,
        };
    },
    option: (styles) => {
        return {
          ...styles,
          padding: '3px',
          margin: 0,
        };
    },
    valueContainer: (styles) => {
        return {
            ...styles,
            ...generalStyles,
        };
    }
  };