import React from 'react';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';
import DocumentCountersModal from '../modals/DocumentCountersModal';
import InvoiceSalesModal from '../modals/InvoiceSalesModal';

export default function AccountingNavbar() {
    // Document counters modal controls
    const [valueDocumentCountersModal, setDocumentCountersModal] = React.useState(false);
    const openDocumentCountersModal = () => setDocumentCountersModal(true);
    const closeDocumentCountersModal = () => setDocumentCountersModal(false);

    // Add new sales invoice modal controls
    const [valueInvoiceSalesModal, setInvoiceSalesModal] = React.useState(false);
    const openInvoiceSalesModal = () => setInvoiceSalesModal(true);
    const closeInvoiceSalesModal = () => setInvoiceSalesModal(false);

    return (
        <>
            <nav className="nav bg-light border-bottom">
                <div className="d-flex">
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle border-right" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-plus mr-1"></i> Добави
                        </Link>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <button className="dropdown-item dropdown-item-sm" onClick={openInvoiceSalesModal}>Фактура</button>
                            <button className="dropdown-item dropdown-item-sm">Проформа</button>
                            <button className="dropdown-item dropdown-item-sm">Дебитно</button>
                            <button className="dropdown-item dropdown-item-sm">Кредитно</button>
                            <div className="dropdown-submenu">
                                <Link className="dropdown-item dropdown-toggle" to={`#`}>Протокол</Link>
                                <ul className="dropdown-menu">
                                    <button className="dropdown-item">Приемо-предавателен протокол</button>
                                    <button className="dropdown-item">Протокол по ЗДДС</button>
                                    <button className="dropdown-item">Протокол по ЗДДС (ВОП)</button>
                                </ul>
                            </div>
                            <button className="dropdown-item dropdown-item-sm">Стокова разписка</button>
                            <button className="dropdown-item dropdown-item-sm">Сторно за продажба</button>
                        </div>
                    </li>
                    
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Фактури
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Проформи
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Дебитни известия
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Кредитни известия
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Протоколи
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Стокови разписки
                        </Link>
                    </li>
                    {/* <li className="nav-item ">
                    <Link className="btn btn-transparent mt-1" to={`/accounting/chart`} id="navbarDropdown" aria-haspopup="true" aria-expanded="false" data-tip data-for="showAccountingBooksTooltip">
                        <i className="fas fa-book text-primary" /> 
                    </Link>
                    <ReactTooltip id="showAccountingBooksTooltip" place="bottom" type="info">
                        <span>Счетоводен сметкоплан</span>
                    </ReactTooltip>
                </li>
                <li className="nav-item ">
                    <Link className="btn btn-transparent mt-1" to={`#`} id="navbarDropdown" aria-haspopup="true" aria-expanded="false" data-tip data-for="showPNLTooltip">
                        <i className="fas fa-file-invoice-dollar text-primary" /> 
                    </Link>
                    <ReactTooltip id="showPNLTooltip" place="bottom" type="info">
                        <span>ОПР</span>
                    </ReactTooltip>
                </li>
                <li className="nav-item ">
                    <Link className="btn btn-transparent mt-1" to={`#`} id="navbarDropdown" aria-haspopup="true" aria-expanded="false" data-tip data-for="showBalanceSheetTooltip">
                        <i className="fas fa-file-contract text-primary" /> 
                    </Link>
                    <ReactTooltip id="showBalanceSheetTooltip" place="bottom" type="info">
                        <span>Баланс</span>
                    </ReactTooltip>
                </li> */}
                </div>
                <div className="ml-auto mr-2">
                    <form className="d-flex mt-1">
                        <input type="text" placeholder="Бързо търсене" className="form-control mr-2" />
                        <button data-tip data-for="showExtendedFilterTooltip" type="button" className="btn btn-transparent mr-2">
                            <i className="fas fa-filter text-primary" />
                        </button>
                        <ReactTooltip id="showExtendedFilterTooltip" place="left" type="info">
                            <span>Подробен филтър</span>
                        </ReactTooltip>
                    </form>
                </div>
            </nav>

            <DocumentCountersModal
                value={valueDocumentCountersModal}
                open={openDocumentCountersModal}
                close={closeDocumentCountersModal}
            />

            <InvoiceSalesModal
                value={valueInvoiceSalesModal}
                open={openInvoiceSalesModal}
                close={closeInvoiceSalesModal}
            />

        </>);
}