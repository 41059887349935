import React from 'react';
import Modal from 'react-modal';

import Buyer from './InvoiceSales/Buyer';
import Seller from './InvoiceSales/Seller';
import Invoice from './InvoiceSales/Invoice';

import InvoiceTable from './InvoiceSales/InvoiceTable';
import InvoiceDetails from './InvoiceSales/InvoiceDetails';
import DetailsBlock from './InvoiceSales/DetailsBlock';

export default function InvoiceSalesModal(props) {

    const [defaultValues, setDefaultValues] = React.useState({
        paymentMethod: 'По сметка',
        taxObligations: 'Облагаема сделка',
        dealMeetingPlace: 'София'
    });

    const [showDetailsBlock, setShowDetailsBlock] = React.useState(true);

    // If props are not loaded the element will sometimes render incorrectly
    if (props.close, props.value, props.open) {
        return (
            <Modal
                isOpen={props.value}
                onRequestClose={props.close}
                className="custom-modal border"
                overlayClassName="custom-modal-overlay"
                contentLabel="Add new sales invoice"
            >

                <div className="container-fluid bg-light border-bottom">
                    <div className="row">
                        <div className="col-9">
                            <h4 className="mt-2">Въвеждане на нова фактура за продажба</h4>
                        </div>
                        <div className="col-3">
                            <button type="button" className="close p-2" onClick={props.close}>
                                <span>&times;</span>
                            </button>
                            <button type="button" className="close p-2" onClick={e => {
                                e.preventDefault();
                                alert('История на промените.');
                                }}>
                                <i className="fas fa-history" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row py-2 bg-light">
                        <div className="col-12">
                            <div className="row">

                                <div className="col-4">
                                    <Buyer />
                                </div>

                                <div className="col-4 border-left border-right">
                                    <Invoice />
                                </div>

                                <div className="col-4">
                                    <Seller />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-12">
                            <InvoiceTable defaultValues={defaultValues} setDefaultValues={setDefaultValues} />
                        </div>

                    </div>
                    
                    <div className="row py-2 bg-light">
                        <InvoiceDetails defaultValues={defaultValues} setDefaultValues={setDefaultValues} />
                    </div>

                    <div className="row">
                        <div className="col-12 m-0 p-0 border-top border-bottom">
                            <button type="button" onClick={e => setShowDetailsBlock(!showDetailsBlock)} className="btn btn-light font-weight-bold col-sm-12 text-left">ДОПЪЛНИТЕЛЕН БЛОК</button>
                        </div>
                    </div>
                    <div className="row" className={(showDetailsBlock === true) ? 'd-block' : 'd-none'}>

                        <div className="col-12">
                            <DetailsBlock />
                        </div>

                    </div>
                    
                    <div className="row">
                        <div className="col-12 m-0 p-3 border-top border-bottom">
                            <div className="row">
                                <div className="col-8">
                                    <form>
                                        <div className="form-group row">
                                            <label className="col-form-label ml-3" style={{"width": '130px'}}><a href="" onClick={e => {
                                                e.preventDefault();
                                                alert('Modal: Добавяне на валута.')
                                            }}>Валута на док.</a></label>
                                            <select className="form-control col-2">
                                                <option>BGN &#10003;</option>
                                                <option>USD</option>
                                                <option>EUR</option>
                                            </select>
                                            
                                            <label className="col-form-label ml-3" style={{"width": '100px'}}>Курс на док.</label>
                                            <input type="number" class="form-control col-2" step="0.01" />
                                            <label className="col-form-label col-2">за 1 BGN</label>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <button type="button" className="btn btn-danger mr-2">Отказ</button>
                                    <button type="button" className="btn btn-primary mr-2">Запис</button>
                                    <button type="button" className="btn btn-primary">Запис и преглед</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Modal>
        );
    } else {
        return (<></>);
    }
}