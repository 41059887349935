import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumbs(props) {
    const printBreadcrumbs = props.data.map(item => {
        return (<li key={ item.label } className={`breadcrumb-item ${item.active ? 'active' : ''}`}><Link to={item.address}>{item.label}</Link></li>);
    });

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb border-top mb-0 py-2">
                { printBreadcrumbs }
            </ol>
        </nav>
    );
}