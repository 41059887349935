import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import Navbar from './components/nav/Navbar';
import Routes from './routes/Routes';

// Access ENV to get API address
console.log(process.env.REACT_APP_API);

function App() {
  return (
    <BrowserRouter>

      <Navbar />
      <Routes />
      
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));