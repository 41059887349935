import React from 'react';
import ReactTooltip from 'react-tooltip';

export default function InvoiceDetails(props) {
    return (
        <>
        <div className="col-4">
            <form>
                <div className="form-group row">
                    <label className="col-sm-6 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Държава на доставка.');
                                }}>Държава на доставка</a></label>
                    <div className="col-sm-6">
                        <select className="form-control">
                            <option>България</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-6 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Място на доставка.');
                                }}>Място на доставка</a></label>
                    <div className="col-sm-6">
                        <input type="text" className="form-control" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-6 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Incoterms.');
                                }}>Incoterms</a></label>
                    <div className="col-sm-6">
                        <select className="form-control">
                            <option>EXW</option>
                            <option>FCA</option>
                            <option>CPT</option>с
                            <option>CIP</option>
                            <option>DAP</option>
                            <option>DPU</option>
                            <option>DDP</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div className="col-4 border-left border-right">
            <form>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Получил.');
                                }}>Получател</a></label>
                    <div className="col-sm-8">
                        <input type="text" className="form-control" placeholder={`Име на получателя`} />
                    </div>
                </div>
                <div className="form-group row">
                    <label data-tip data-for="showMaturityDateTooltip" className="col-sm-4 col-form-label">Падеж</label>
                    <ReactTooltip id="showMaturityDateTooltip" place="top" type="info">
                        <span>Срок/дата на падеж</span>
                    </ReactTooltip>
                    <div className="col-sm-4">
                        <input type="number" className="form-control" />
                    </div>
                    <div className="col-sm-4">
                        <input type="date" className="form-control" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Начин на плащане.');
                                }}>Начин на плащане</a></label>
                    <div className="col-sm-8">
                        <select defaultValue={props.defaultValues.paymentMethod} onChange={e => props.setDefaultValues({...props.defaultValues, paymentMethod: e.target.value})} className="form-control">
                            <option>В брой</option>
                            <option>По сметка</option>
                            <option>С карта</option>с
                            <option>Друг вид</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div className="col-4">
            <form>
                <div className="form-group row">
                    <label className="col-sm-6 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Стоката е от склад.');
                                }}>Стоката е от склад</a></label>
                    <div className="col-sm-6">
                        <select className="form-control">
                            <option>София</option>
                            <option>Русе</option>
                            <option>Варна</option>
                            <option>Друг</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-6 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Място на издаване.');
                                }}>Място на издаване</a></label>
                    <div className="col-sm-6">
                        <input type="text" className="form-control" defaultValue={props.defaultValues.dealMeetingPlace} onChange={e => props.setDefaultValues({...props.defaultValues, dealMeetingPlace: e.target.value})} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-6 col-form-label"><a href="" onClick={e => {
                                    e.preventDefault();
                                    alert('Modal: Съставител.');
                                }}>Съставител</a></label>
                    <div className="col-sm-6">
                        <select className="form-control">
                            <option>Иван Петров</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        </>
    );
}