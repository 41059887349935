import React from 'react';
import InvoiceModal from './InvoiceModal';

export default function Invoice(props) {
    const [ valueInvoiceModal, setInvoiceModal ] = React.useState(false);
    const openInvoiceModal = () => setInvoiceModal(true);
    const closeInvoiceModal = () => setInvoiceModal(false);

    const [ invoiceModalSalesDocType, setInvoiceModalSalesDocType ] = React.useState({
        documentType: 'Продажба',
        documentAssetType: 'Избери',
        documentFor: 'Фактура'
    });

    return (
        <>
        <form>
            <div className="form-group row">
                <div className="col-sm-8 m-auto">
                    <button className="col-sm-12 font-weight-bold btn btn-light" onClick={e => {
                        e.preventDefault();
                        openInvoiceModal();
                    }}><span className="text-primary" style={{ 'fontSize': '1rem' }}>{(invoiceModalSalesDocType.documentFor).toUpperCase()}</span> <br /> <small>({invoiceModalSalesDocType.documentType}/{invoiceModalSalesDocType.documentAssetType})</small></button>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-3 text-right">
                    <label className="col-form-label">Документ №</label>
                </div>
                <div className="form-group col-sm-6">
                    <input type="text" className="form-control" />
                </div>
                <div className="form-group col-sm-3"></div>
            </div>
            <div className="form-row">
                <div className="form-group col-sm-4">
                    <label className="col-form-label">Дата на <br /> документа </label>
                        <input type="date" className="form-control" />
                </div>
                <div className="form-group col-sm-4">
                    <label className="col-form-label">Дата на <br /> дан. събитие </label>
                    <div>
                        <input type="date" className="form-control" />
                    </div>
                </div>
                <div className="form-group col-sm-4">
                    <label className="col-form-label">Дата на <br /> въвеждане </label>
                        <input type="date" className="form-control" />
                </div>
                {/* <div className="form-group col-sm-4">
                    <label className="col-form-label">Дата на <br /> контировка </label>
                    <div>
                        <input type="date" className="form-control" />
                    </div>
                </div> */}
            </div>
        </form>
        <InvoiceModal 
            value={valueInvoiceModal}
            open={openInvoiceModal} 
            close={closeInvoiceModal} 
            state={invoiceModalSalesDocType}
            setState={setInvoiceModalSalesDocType}
        />
        </>
    );
}
