import React, { useState } from 'react';
import Breadcrumbs from '../../nav/Breadcrumbs';
import CounterpartiesNavbar from './nav/CounterpartiesNavbar';
import Loader from '../../misc/Loader';

export default function Counterparties() {
    let breadcrumbs = [
        {
            address: '/',
            label: 'LINBRIX',
            active: false,
        },
        {
            address: '/counterparties',
            label: 'Контрагенти',
            active: true,
        }
    ];
    return (
    <>
    <Breadcrumbs data={ breadcrumbs } />
    <CounterpartiesNavbar />
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">

                <div className="float-left">
                    <div className="my-1" style={{'backgroundColor': '#dee2e6'}}>
                        <button className="btn"><i className="fas fa-pen" /></button>
                        <button className="btn"><i className="fas fa-trash" /></button>
                        <button className="btn"><i className="fas fa-copy" /></button>
                    </div>
                </div>
                <div className="float-right">
                    <form className="form-inline my-1">   
                        <div className="form-group row mr-0">
                            <label htmlFor="tableYearSelect" className="col-form-label mr-2">Визуализиран период:</label>
                            <select className="custom-select" id="tableYearSelect">
                                <option disabled>Изберете период</option>
                                <option value="1" selected>Всички</option>
                                <option value="2">2020 год.</option>
                                <option value="4">Текущ мес.</option>
                                <option value="5">Предх. мес.</option>
                            </select>
                        </div>
                    </form>
                </div>

                <table className="table table-hover table-bordered">
                    <thead className="thead-light">
                        <tr>
                        <th scope="col" className="text-center" style={{'width': '22px'}}><input type="checkbox" /></th>
                        <th scope="col" style={{'width': '22px'}}></th>
                        <th scope="col">Име на документа</th>
                        <th scope="col">Номер</th>
                        <th scope="col">Дата</th>
                        <th scope="col">Стойност</th>
                        <th scope="col">Валута</th>
                        <th scope="col">Контрагент</th>
                        <th scope="col">Булстат</th>
                        <th scope="col">МОЛ</th>
                        <th scope="col">Папка</th>
                        <th scope="col">Номер</th>
                        <th scope="col">Док. е за:</th>
                        <th scope="col">Вид на стоката</th>
                        <th scope="col">Други характеристики</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" className="text-center"><input type="checkbox" /></th>
                            <th scope="row" className="text-center"><i className="fas fa-cog text-primary" /></th>
                            <td>Фактура</td>
                            <td>0000000589</td>
                            <td>12.03.2020</td>
                            <td>444.24</td>
                            <td>BGN</td>
                            <td>ПМ СОФТ ООД</td>
                            <td>204407892</td>
                            <td>Диан Димов</td>
                            <td>/sys/invoice</td>
                            <td>17</td>
                            <td>Покупка</td>
                            <td>Услуга</td>
                            <td>Няма</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    </>
    );
}