import React from 'react';
import Breadcrumbs from '../../nav/Breadcrumbs';
import CounterpartiesNavbar from './nav/CounterpartiesNavbar';

export default function CounterpartyStatusesTasks() {
    let breadcrumbs = [
        {
            address: '/',
            label: 'LINBRIX',
            active: false,
        },
        {
            address: '/counterparties',
            label: 'Контрагенти',
            active: false,
        },
        {
            address: '/counterparties',
            label: 'Настройки',
            active: false,
        },
        {
            address: '/counterparties/statuses/tasks',
            label: 'Статус задачи',
            active: true,
        }
    ];
    return (    
    <>
    <Breadcrumbs data={ breadcrumbs } />
    <CounterpartiesNavbar />
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">

                <div className="float-left">
                    <div className="my-1" style={{'backgroundColor': '#dee2e6'}}>
                        <button className="btn"><i className="fas fa-pen" /></button>
                        <button className="btn"><i className="fas fa-trash" /></button>
                        <button className="btn"><i className="fas fa-copy" /></button>
                    </div>
                </div>
                <div className="float-right">
                    <form className="form-inline my-1">   
                        <div className="form-group row mr-0">
                            <label htmlFor="tableYearSelect" className="col-form-label mr-2">Визуализиран период:</label>
                            <select className="custom-select" id="tableYearSelect">
                                <option disabled>Изберете период</option>
                                <option value="1" selected>Всички</option>
                                <option value="2">2020 год.</option>
                                <option value="4">Текущ мес.</option>
                                <option value="5">Предх. мес.</option>
                            </select>
                        </div>
                    </form>
                </div>

                <table className="table table-hover table-bordered">
                    <thead className="thead-light">
                        <tr>
                        <th scope="col" className="text-center" style={{'width': '22px'}}><input type="checkbox" /></th>
                        <th scope="col" style={{'width': '22px'}}></th>
                        <th scope="col">Наименование</th>
                        <th scope="col">Ранг</th>
                        <th scope="col">Цветови код</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" className="text-center"><input type="checkbox" /></th>
                            <th scope="row" className="text-center"><i className="fas fa-cog text-primary" /></th>
                            <td>НЕОБРАБОТЕНА</td>
                            <td>1</td>
                            <td> <div className="bg-info rounded-sm" style={{'width': '1rem', 'height': '1rem'}}></div> </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-center"><input type="checkbox" /></th>
                            <th scope="row" className="text-center"><i className="fas fa-cog text-primary" /></th>
                            <td>ЗА ИЗПЪЛНЕНИЕ</td>
                            <td>2</td>
                            <td> <div className="bg-info rounded-sm" style={{'width': '1rem', 'height': '1rem'}}></div> </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-center"><input type="checkbox" /></th>
                            <th scope="row" className="text-center"><i className="fas fa-cog text-primary" /></th>
                            <td>В ИЗПЪЛНЕНИЕ</td>
                            <td>3</td>
                            <td> <div className="bg-info rounded-sm" style={{'width': '1rem', 'height': '1rem'}}></div> </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-center"><input type="checkbox" /></th>
                            <th scope="row" className="text-center"><i className="fas fa-cog text-primary" /></th>
                            <td>В ИЗЧАКВАНЕ</td>
                            <td>4</td>
                            <td> <div className="bg-info rounded-sm" style={{'width': '1rem', 'height': '1rem'}}></div> </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-center"><input type="checkbox" /></th>
                            <th scope="row" className="text-center"><i className="fas fa-cog text-primary" /></th>
                            <td>ПРИКЛЮЧЕНА</td>
                            <td>5</td>
                            <td> <div className="bg-info rounded-sm" style={{'width': '1rem', 'height': '1rem'}}></div> </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    </>
    );
}