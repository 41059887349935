import React from 'react';
import ReactTooltip from 'react-tooltip';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { colourStyles } from './ReactSelectStyles';
const animatedComponents = makeAnimated();
const options = [
    { value: 'pib', label: 'ПИБ АД' },
    { value: 'dsk', label: 'Банка ДСК' },
    { value: 'allianz', label: 'Алианц Банк' }
];

export default function Seller(props) {
    return (
        <form>
            <div className="form-group row">
                <div className="col-sm-12">
                    <button className="col-sm-12 font-weight-bold btn btn-light text-left">ПРОДАВАЧ</button>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-12">
                    <input type="text" className="form-control" placeholder="Наименование на фирмата на клиента" defaultValue="ЛИНБРИКС ООД" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">МОЛ</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" placeholder="МОЛ" defaultValue="Иван Петров Иванов" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Държава</label>
                <div className="col-sm-4">
                    <select className="form-control">
                        <option>България</option>
                    </select>
                </div>
                <label className="col-sm-2 col-form-label">Седалище</label>
                <div className="col-sm-4">
                    <input type="text" className="form-control" defaultValue="София" />
                </div>
            </div>
            {/* <div className="form-group row">
                <label className="col-sm-2 col-form-label">Адрес</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" defaultValue="бул. Ботевградско Шосе №491" />
                </div>
            </div> */}
            <div className="form-group row">
                <label data-tip data-for="showTIDTooltip" className="col-sm-2 col-form-label">ЕИК</label>
                <ReactTooltip id="showTIDTooltip" place="bottom" type="info">
                    <span>Идентификационен номер</span>
                </ReactTooltip>
                <div className="col-sm-4">
                    <input type="text" className="form-control" defaultValue="831239547" placeholder="ЕИК" />
                </div>
                <label data-tip data-for="showVATNumberTooltip" className="col-sm-2 col-form-label">ЗДДС/№</label>
                <ReactTooltip id="showVATNumberTooltip" place="bottom" type="info">
                    <span>Идент. ном. по ЗДДС</span>
                </ReactTooltip>
                <div className="col-sm-4">
                    <input type="text" className="form-control" placeholder="ДДС номер" defaultValue="BG831239547" />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label" data-tip data-for="showBankAccountsTooltip" >Сметки</label>
                <ReactTooltip id="showBankAccountsTooltip" place="bottom" type="info">
                    <span>Банкови сметки, които да бъдат <br /> визуализирани във фактурата.</span>
                </ReactTooltip>
                <div className="col-sm-10">
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        styles={colourStyles}
                        placeholder={'Моля, изберете сметка!'}
                    />
                </div>
            </div>
        </form>
    );
}