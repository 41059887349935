import React from 'react';
import ReactTooltip from 'react-tooltip'

import Breadcrumbs from '../../nav/Breadcrumbs';
import AccountingChartNavbar from './nav/AccountingChartNavbar';

const financialAccounts = [
    {
        id: 0,
        label: 'Счетоводни сметки',
        show: true,
        children: [
            { 
                id: 10, 
                label: 	'Капитал', show: true, 
                children: [
                    { 
                        id: 101, 
                        label: 	'Основен капитал, изискващ регистрация', 
                        show: true
                    },
                    { 
                        id: 102, 
                        label: 	'Основен капитал, не изискващ регистрация', 
                        show: true
                    },
                    { 
                        id: 103, 
                        label: 	'Ликвидационен капитал при несъстоятелност и ликвидация', 
                        show: true
                    },
                    { 
                        id: 104, 
                        label: 	'Капитал на предприятия с нестопанска цел', 
                        show: true
                    },
                    { 
                        id: 108, 
                        label: 	'Премии, свързани с капитал', 
                        show: true
                    },
                    { 
                        id: 109, 
                        label: 	'Отбиви (сконто), свързани с капитал', 
                        show: true
                    },
                ],
            },
            { 
                id: 11, 
                label: 	'Резерви', 
                show: true,
                children: [
                    { 
                        id: 111, 
                        label: 	'Общи резерви', 
                        show: true
                    },
                    { 
                        id: 112, 
                        label: 	'Резерви от последваща оценка на дълготрайни активи', 
                        show: true
                    },
                    { 
                        id: 113, 
                        label: 	'Резерви от последваща оценка на краткотрайни активи', 
                        show: true
                    },
                    { 
                        id: 114, 
                        label: 	'Резерви от последваща оценка на финансови инструменти', 
                        show: true
                    },
                    { 
                        id: 115, 
                        label: 	'Резерви от емисия на акции', 
                        show: true
                    },
                    { 
                        id: 119, 
                        label: 	'Други резерви', 
                        show: true
                    },
                ],
            },
            { 
                id: 12, 
                label: 	'Финансови резултати', 
                show: true,
                children: [
                    { 
                        id: 121, 
                        label: 	'Непокрита загуба от минали години', 
                        show: true
                    },
                    { 
                        id: 122, 
                        label: 	'Неразпределена печалба от минали години', 
                        show: true
                    },
                    { 
                        id: 123, 
                        label: 	'Печалби и загуби от текущата година', 
                        show: true
                    },
                    { 
                        id: 124, 
                        label: 	'Резултат при несъстоятелност и ликвидация', 
                        show: true
                    },
                    { 
                        id: 125, 
                        label: 	'Резултат от дейността на предприятия с нестопанска цел', 
                        show: true
                    },
                ],
            },
            
            { 
                id: 13, 
                label: 	'Специфични некапиталови резерви', 
                show: true,
                children: [
                    { 
                        id: 131, 
                        label: 	'Резерви от животозастраховане', 
                        show: true
                    },
                    { 
                        id: 132, 
                        label: 	'Пренос - премийни резерви', 
                        show: true
                    },
                    { 
                        id: 133, 
                        label: 	'Резерви за предстоящи плащания', 
                        show: true
                    },
                    { 
                        id: 134, 
                        label: 	'Резерви за запасен фонд', 
                        show: true
                    },
                    { 
                        id: 135, 
                        label: 	'Резерви за презастраховане', 
                        show: true
                    },
                    { 
                        id: 136, 
                        label: 	'Други резерви по застрахователни договори', 
                        show: true
                    },
                    { 
                        id: 137, 
                        label: 	'Резерви на инвестиционни дружества', 
                        show: true
                    },
                    { 
                        id: 138, 
                        label: 	'Резерви на пенсионни дружества', 
                        show: true
                    },
                ],
            },
            { 
                id: 14, 
                label: 	'Парични емисии', 
                show: true,
                children: [
                    { 
                        id: 141, 
                        label: 	'Банкноти за обращение', 
                        show: true
                    },
                    { 
                        id: 142, 
                        label: 	'Монети за обращение', 
                        show: true
                    },
                ],
            },
            { 
                id: 15, 
                label: 	'Получени заеми', 
                show: true,
                children: [
                    { 
                        id: 151, 
                        label: 	'Получени краткосрочни заеми', 
                        show: true
                    },
                    { 
                        id: 152, 
                        label: 	'Получени дългосрочни заеми', 
                        show: true
                    },
                    { 
                        id: 153, 
                        label: 	'Дългови инструменти', 
                        show: true
                    },
                    { 
                        id: 159, 
                        label: 	'Други заеми и дългове', 
                        show: true
                    },
                ],
            },
            { 
                id: 16, 
                label: 	'Привлечени средства по депозитни сметки', 
                show: true,
                children: [
                    { 
                        id: 161, 
                        label: 	'Привлечени средства по депозитни сметки на банки и др.финансови институции', 
                        show: true
                    },
                    { 
                        id: 162, 
                        label: 	'Привлечени средства по депозитни сметки на нефинансови предприятия и др.клиенти', 
                        show: true
                    },
                ]
            },
            { 
                id: 17, 
                label: 	'Привлечени средства по разплащателни и други сметки', 
                show: true,
                children: [
                    { 
                        id: 171, 
                        label: 	'Привлечени средства по разплащателни сметки на банки и др. финансови институции', 
                        show: true
                    },
                    { 
                        id: 172, 
                        label: 	'Привлечени средства по разплащателни сметки на нефинансови предприятия и други клиенти', 
                        show: true
                    },
                    { 
                        id: 173, 
                        label: 	'Привлечени средства по специални сметки', 
                        show: true
                    },
                    { 
                        id: 174, 
                        label: 	'Привлечени средства по влогови сметки', 
                        show: true
                    },
                    { 
                        id: 179, 
                        label: 	'Привлечени средства по други сметки', 
                        show: true
                    },
                ],
            },
            { 
                id: 18, 
                label: 	'Привлечени средства по сметки на участници в осигурителни фондове', 
                show: true,
                children: [
                    { 
                        id: 181, 
                        label: 	'Привлечени средства по сметки на участници в пенсионното осигуряване', 
                        show: true
                    },
                    { 
                        id: 182, 
                        label: 	'Привлечени средства по сметки на участници в здравното осигуряване', 
                        show: true
                    },
                    { 
                        id: 189, 
                        label: 	'Привлечени средства по сметки на участници в други осигурителни фондове', 
                        show: true
                    },
                ],
            },
            { 
                id: 19, 
                label: 	'Премии и сконто по финансови инструменти', 
                show: true,
                children: [
                    { 
                        id: 191, 
                        label: 	'Премии, произтичащи от финансови инструменти', 
                        show: true
                    },
                    { 
                        id: 192, 
                        label: 	'Сконто от финансови инструменти', 
                        show: true
                    },
                ],
            },
            { 
                id: 20, 
                label: 	'Дълготрайни материални активи', 
                show: true,
                children: [
                    { 
                        id: 200, 
                        label: 	'Земи (терени)', 
                        show: true
                    },
                    { 
                        id: 201, 
                        label: 	'Подобрения върху земите', 
                        show: true
                    },
                    { 
                        id: 202, 
                        label: 	'Сгради и конструкции', 
                        show: true
                    },
                    { 
                        id: 203, 
                        label: 	'Компютърна техника', 
                        show: true
                    },
                    { 
                        id: 204, 
                        label: 	'Съоръжения', 
                        show: true
                    },
                    { 
                        id: 205, 
                        label: 	'Машини и оборудване', 
                        show: true
                    },
                    { 
                        id: 206, 
                        label: 	'Транспортни средства', 
                        show: true
                    },
                    { 
                        id: 207, 
                        label: 	'Офис обзавеждане', 
                        show: true
                    },
                    { 
                        id: 208, 
                        label: 	'Библиотечни фондове', 
                        show: true
                    },
                    { 
                        id: 209, 
                        label: 	'Други ДМА', 
                        show: true
                    },
                ],
            },
            { 
                id: 21, 
                label: 	'Дълготрайни нематериални активи', 
                show: true,
                children: [
                    { 
                        id: 211, 
                        label: 	'Продукти от развойна дейност', 
                        show: true
                    },
                    { 
                        id: 212, 
                        label: 	'Програмни продукти', 
                        show: true
                    },
                    { 
                        id: 213, 
                        label: 	'Права върху интелектуална собственост', 
                        show: true
                    },
                    { 
                        id: 214, 
                        label: 	'Права върху индустриална собственост', 
                        show: true
                    },
                    { 
                        id: 219, 
                        label: 	'Други дълготрайни нематериални активи', 
                        show: true
                    },
                ],
            },
            { 
                id: 22, 
                label: 	'Дългосрочни финансови активи', 
                show: true,
                children: [
                    { 
                        id: 221, 
                        label: 	'Инвестиции в дъщерни предприятия', 
                        show: true
                    },
                    { 
                        id: 222, 
                        label: 	'Инвестиции в асоциирани предприятия', 
                        show: true
                    },
                    { 
                        id: 223, 
                        label: 	'Инвестиции в смесени предприятия', 
                        show: true
                    },
                    { 
                        id: 224, 
                        label: 	'Инвестиционни имоти', 
                        show: true
                    },
                    { 
                        id: 225, 
                        label: 	'Финансови активи, държани до настъпване на падеж', 
                        show: true
                    },
                    { 
                        id: 226, 
                        label: 	'Финансови активи, обявени за продажба', 
                        show: true
                    },
                    { 
                        id: 227, 
                        label: 	'Финансови активи, заложени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 228, 
                        label: 	'Държавни ценни книжа', 
                        show: true
                    },
                    { 
                        id: 229, 
                        label: 	'Други дългосрочни финансови активи', 
                        show: true
                    },
                ],
            },
            { 
                id: 23, 
                label: 	'Търговска репутация', 
                show: true,
                children: [
                    { 
                        id: 231, 
                        label: 	'Положителна търговска репутация', 
                        show: true
                    },
                    { 
                        id: 232, 
                        label: 	'Отрицателна търговска репутация', 
                        show: true
                    },
                ],
            },
            { 
                id: 24, 
                label: 	'Амортизация', 
                show: true,
                children: [
                    { 
                        id: 241, 
                        label: 	'Амортизация на ДМА', 
                        show: true,
                        children: [
                            { 
                                id: 2411, 
                                label: 'Амортизация на подобрения върху земите', 
                                show: true
                            },
                            { 
                                id: 2412, 
                                label: 'Амортизация на сгради и конструкции', 
                                show: true
                            },
                            { 
                                id: 2413, 
                                label: 'Амортизация на компютърна техника', 
                                show: true
                            },
                            { 
                                id: 2414, 
                                label: 'Амортизация на съоръжения', 
                                show: true
                            },
                            { 
                                id: 2415, 
                                label: 'Амортизация на машини и оборудване', 
                                show: true
                            },
                            { 
                                id: 2416, 
                                label: 'Амортизация на транспортни средства', 
                                show: true
                            },
                            { 
                                id: 2417, 
                                label: 'Амортизация на офис обзавеждане', 
                                show: true
                            },
                            { 
                                id: 2418, 
                                label: 'Амортизация на библиотечни фондове', 
                                show: true
                            },
                            { 
                                id: 2419, 
                                label: 'Амортизация на други ДМА', 
                                show: true
                            },
                        ],
                    },
                    { 
                        id: 242, 
                        label: 	'Амортизация на ДНМА', 
                        show: true,
                        children: [
                            { 
                                id: 2421, 
                                label: 'Амортизация на продукти от развойна дейност', 
                                show: true
                            },
                            { 
                                id: 2422, 
                                label: 'Амортизация на програмни продукти', 
                                show: true
                            },
                            { 
                                id: 2423, 
                                label: 'Амортизация на права върху интелектуална собственост', 
                                show: true
                            },
                            { 
                                id: 2424, 
                                label: 'Амортизация на права върху индустриална собственост', 
                                show: true
                            },
                            { 
                                id: 2429, 
                                label: 'Амортизация на други ДНМА', 
                                show: true
                            },
                        ],
                    },
                ],
            },
            { 
                id: 26, 
                label: 	'Дългосрочни вземания и предоставени заеми', 
                show: true,
                children: [
                    { 
                        id: 261, 
                        label: 	'Дългосрочни вземания и предоставени заеми на банки и др. финансови институции', 
                        show: true
                    },
                    { 
                        id: 262, 
                        label: 	'Дългосрочни вземания и предоставени заеми на нефинансови предприятия и други клиенти', 
                        show: true
                    },
                    { 
                        id: 263, 
                        label: 	'Просрочени дългосрочни вземания и предоставени заеми', 
                        show: true
                    },
                    { 
                        id: 264, 
                        label: 	'Дългосрочни вземания и предоставени заеми, заложени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 269, 
                        label: 	'Други дългосрочни вземания и предоставени заеми', 
                        show: true
                    },
                ],
            },
            { 
                id: 27, 
                label: 	'Дълготрайни биологични активи', 
                show: true,
                children: [
                    { 
                        id: 271, 
                        label: 	'Гори', 
                        show: true
                    },
                    { 
                        id: 272, 
                        label: 	'Трайни насаждения - плододаващи', 
                        show: true
                    },
                    { 
                        id: 273, 
                        label: 	'Трайни насаждения - неплододаващи', 
                        show: true
                    },
                    { 
                        id: 274, 
                        label: 	'Животни в основни стада', 
                        show: true
                    },
                    { 
                        id: 279, 
                        label: 	'Други дълготрайни биологични активи', 
                        show: true
                    },
                ],
            },
            { 
                id: 30, 
                label: 	'Материали, продукция и стоки.', 
                show: true,
                children: [
                    { 
                        id: 301, 
                        label: 	'Доставки', 
                        show: true
                    },
                    { 
                        id: 302, 
                        label: 	'Материали', 
                        show: true
                    },
                    { 
                        id: 303, 
                        label: 	'Продукция', 
                        show: true
                    },
                    { 
                        id: 304, 
                        label: 	'Стоки', 
                        show: true
                    },
                ],
            },
            { 
                id: 31, 
                label: 	'Краткотрайни биологични активи', 
                show: true,
                children: [
                    { 
                        id: 311, 
                        label: 	'Дребни продуктивни животни', 
                        show: true
                    },
                    { 
                        id: 312, 
                        label: 	'Птици - основни стада', 
                        show: true
                    },
                    { 
                        id: 313, 
                        label: 	'Пчелни семейства', 
                        show: true
                    },
                    { 
                        id: 314, 
                        label: 	'Млади животни', 
                        show: true
                    },
                    { 
                        id: 315, 
                        label: 	'Животни за угояване', 
                        show: true
                    },
                    { 
                        id: 316, 
                        label: 	'Животни за експериментални цели', 
                        show: true
                    },
                    { 
                        id: 319, 
                        label: 	'Други краткотрайни биологични активи', 
                        show: true
                    },
                ],
            },
            { 
                id: 40, 
                label: 	'Доставчици и свързани с тях сметки', 
                show: true,
                children: [
                    { 
                        id: 401, 
                        label: 	'Доставчици', 
                        show: true
                    },
                    { 
                        id: 402, 
                        label: 	'Доставчици по аванси', 
                        show: true
                    },
                    { 
                        id: 403, 
                        label: 	'Доставчици по търговски кредити', 
                        show: true
                    },
                    { 
                        id: 404, 
                        label: 	'Доставчици по доставки при определени условия', 
                        show: true
                    },
                    { 
                        id: 405, 
                        label: 	'Доставчици по доставки със свързани лица', 
                        show: true
                    },
                    { 
                        id: 409, 
                        label: 	'Други разчети с доставчици', 
                        show: true
                    },
                ],
            },
            { 
                id: 41, 
                label: 	'Клиенти и свързани с тях сметки', 
                show: true,
                children: [
                    { 
                        id: 411, 
                        label: 	'Клиенти', 
                        show: true
                    },
                    { 
                        id: 412, 
                        label: 	'Клиенти по аванси', 
                        show: true
                    },
                    { 
                        id: 413, 
                        label: 	'Клиенти по търговски кредити', 
                        show: true
                    },
                    { 
                        id: 414, 
                        label: 	'Клиенти по продажби при определени условия', 
                        show: true
                    },
                    { 
                        id: 415, 
                        label: 	'Клиенти по продажби със свързани лица', 
                        show: true
                    },
                    { 
                        id: 419, 
                        label: 	'Други разчети с клиенти', 
                        show: true
                    },
                ],
            },
            { 
                id: 42, 
                label: 	'Персонал и съдружници', 
                show: true,
                children: [
                    { 
                        id: 421, 
                        label: 	'Персонал', 
                        show: true
                    },
                    { 
                        id: 422, 
                        label: 	'Подотчетни лица', 
                        show: true
                    },
                    { 
                        id: 423, 
                        label: 	'Задължения по неизползвани отпуски', 
                        show: true
                    },
                    { 
                        id: 424, 
                        label: 	'Вземания от съучастия', 
                        show: true
                    },
                    { 
                        id: 425, 
                        label: 	'Задължения за съучастия', 
                        show: true
                    },
                    { 
                        id: 426, 
                        label: 	'Вземания по записани дялови вноски', 
                        show: true
                    },
                    { 
                        id: 429, 
                        label: 	'Други разчети с персонала и съдружниците', 
                        show: true
                    },
                ],
            },
            { 
                id: 43, 
                label: 	'Разчети по преводи и вътрешни разчети', 
                show: true,
                children: [
                    { 
                        id: 431, 
                        label: 	'Разчети по междубанкови операции', 
                        show: true
                    },
                    { 
                        id: 432, 
                        label: 	'Вътрешни разчети по междубанкови операции', 
                        show: true
                    },
                    { 
                        id: 433, 
                        label: 	'Вътрешни разчети по вътрешнобанкови операции', 
                        show: true
                    },
                    { 
                        id: 439, 
                        label: 	'Други вътрешни разчети', 
                        show: true
                    },
                ],
            },
            { 
                id: 44, 
                label: 	'Вземания по липси, начети и съдебни спорове', 
                show: true,
                children: [
                    { 
                        id: 441, 
                        label: 	'Вземания по рекламации', 
                        show: true
                    },
                    { 
                        id: 442, 
                        label: 	'Вземания по липси и начети', 
                        show: true
                    },
                    { 
                        id: 443, 
                        label: 	'Ценови разлики по липси и начети', 
                        show: true
                    },
                    { 
                        id: 444, 
                        label: 	'Вземания по съдебни спорове', 
                        show: true
                    },
                    { 
                        id: 445, 
                        label: 	'Присъдени вземания', 
                        show: true
                    },
                ],
            },
            { 
                id: 45, 
                label: 	'Разчети с бюджета и с ведомства', 
                show: true,
                children: [
                    { 
                        id: 451, 
                        label: 	'Разчети с общините', 
                        show: true
                    },
                    { 
                        id: 452, 
                        label: 	'Разчети за данък върху печалбата', 
                        show: true
                    },
                    { 
                        id: 453, 
                        label: 	'Разчети за ДДС', 
                        show: true,
                        children: [
                            { 
                                id: 4531, 
                                label: 	'Начислен данък покупките', 
                                show: true
                            },
                            { 
                                id: 4532, 
                                label: 	'Начислен данък продажбите', 
                                show: true
                            },
                            { 
                                id: 4538, 
                                label: 	'Данък за възстановяване', 
                                show: true
                            },
                            { 
                                id: 4539, 
                                label: 	'Данък за внасяне', 
                                show: true
                            },
                        ],
                    },
                    { 
                        id: 454, 
                        label: 	'Разчети за данъци върху доходи на физически лица', 
                        show: true
                    },
                    { 
                        id: 455, 
                        label: 	'Разчети с министерства', 
                        show: true
                    },
                    { 
                        id: 456, 
                        label: 	'Разчети за акцизи', 
                        show: true
                    },
                    { 
                        id: 457, 
                        label: 	'Разчети с митници', 
                        show: true
                    },
                    { 
                        id: 459, 
                        label: 	'Други разчети с бюджета и с ведомства', 
                        show: true
                    },
                ],
            },
            { 
                id: 46, 
                label: 	'Разчети с осигурители', 
                show: true,
                children: [
                    { 
                        id: 461, 
                        label: 	'Разчети с НОИ', 
                        show: true
                    },
                    { 
                        id: 462, 
                        label: 	'Разчети за доброволно социално осигуряване', 
                        show: true
                    },
                    { 
                        id: 463, 
                        label: 	'Разчети за здравно осигуряване', 
                        show: true
                    },
                    { 
                        id: 464, 
                        label: 	'Разчети за еднократни помощи и детски добавки', 
                        show: true
                    },
                    { 
                        id: 469, 
                        label: 	'Други разчети с осигурители', 
                        show: true
                    },
                ],
            },
            { 
                id: 47, 
                label: 	'Разчети с международни финансови институции', 
                show: true,
                children: [
                    { 
                        id: 471, 
                        label: 	'Разчети с Международния валутен фонд', 
                        show: true
                    },
                    { 
                        id: 472, 
                        label: 	'Разчети със Световната банка', 
                        show: true
                    },
                    { 
                        id: 473, 
                        label: 	'Разчети с други международни финансови институции', 
                        show: true
                    },
                    { 
                        id: 474, 
                        label: 	'Разчети по междуправителствени спогодби', 
                        show: true
                    },
                ],
            },
            { 
                id: 48, 
                label: 	'Разчети по застраховане и съзастраховане', 
                show: true,
                children: [
                    { 
                        id: 481, 
                        label: 	'Разчети по предстоящи плащания', 
                        show: true
                    },
                    { 
                        id: 482, 
                        label: 	'Разчети при презастраховане и съзастраховане', 
                        show: true
                    },
                    { 
                        id: 483, 
                        label: 	'Разчети с презастрахователи', 
                        show: true
                    },
                    { 
                        id: 484, 
                        label: 	'Разчети със седанти', 
                        show: true
                    },
                    { 
                        id: 489, 
                        label: 	'Други разчети', 
                        show: true
                    },
                ],
            },
            { 
                id: 49, 
                label: 	'Разни дебитори и кредитори', 
                show: true,
                children: [
                    { 
                        id: 490, 
                        label: 	'Доверители', 
                        show: true
                    },
                    { 
                        id: 491, 
                        label: 	'Разчети за гаранции', 
                        show: true
                    },
                    { 
                        id: 492, 
                        label: 	'Разчети със собственици', 
                        show: true
                    },
                    { 
                        id: 493, 
                        label: 	'Разчети по застраховане', 
                        show: true
                    },
                    { 
                        id: 494, 
                        label: 	'Разчети по лихви', 
                        show: true
                    },
                    { 
                        id: 495, 
                        label: 	'Разчети по отсрочени данъци', 
                        show: true
                    },
                    { 
                        id: 496, 
                        label: 	'Провизии, признати като пасиви', 
                        show: true
                    },
                    { 
                        id: 497, 
                        label: 	'Други провизии', 
                        show: true
                    },
                    { 
                        id: 498, 
                        label: 	'Други дебитори', 
                        show: true
                    },
                    { 
                        id: 499, 
                        label: 	'Други кредитори', 
                        show: true
                    },
                ],
            },
            { 
                id: 50, 
                label: 	'Парични средства', 
                show: true,
                children: [
                    { 
                        id: 500, 
                        label: 	'Парични средства, заложени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 501, 
                        label: 	'Каса в левове', 
                        show: true
                    },
                    { 
                        id: 502, 
                        label: 	'Каса във валута', 
                        show: true
                    },
                    { 
                        id: 503, 
                        label: 	'Разплащателна сметка в левове', 
                        show: true
                    },
                    { 
                        id: 504, 
                        label: 	'Разплащателна сметка във валута', 
                        show: true
                    },
                    { 
                        id: 505, 
                        label: 	'Акредитиви в левове', 
                        show: true
                    },
                    { 
                        id: 506, 
                        label: 	'Акредитиви във валута', 
                        show: true
                    },
                    { 
                        id: 507, 
                        label: 	'Предоставени депозити', 
                        show: true
                    },
                    { 
                        id: 508, 
                        label: 	'Разплащателни чекове', 
                        show: true
                    },
                    { 
                        id: 509, 
                        label: 	'Други парични средства', 
                        show: true
                    },
                ],
            },
            { 
                id: 51, 
                label: 	'Краткосрочни финансови активи', 
                show: true,
                children: [
                    { 
                        id: 511, 
                        label: 	'Финансови активи, държани за търгуване', 
                        show: true
                    },
                    { 
                        id: 512, 
                        label: 	'Изкупени собствени акции', 
                        show: true
                    },
                    { 
                        id: 513, 
                        label: 	'Финансови активи, заложени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 514, 
                        label: 	'Изкупени собствени облигации', 
                        show: true
                    },
                    { 
                        id: 515, 
                        label: 	'Държавни ценни книжа', 
                        show: true
                    },
                    { 
                        id: 516, 
                        label: 	'Благородни метали и скъпоценни камъни', 
                        show: true
                    },
                    { 
                        id: 519, 
                        label: 	'Други краткосрочни финансови активи', 
                        show: true
                    },
                ],
            },
            { 
                id: 52, 
                label: 	'Краткосрочни вземания и заеми', 
                show: true,
                children: [
                    { 
                        id: 521, 
                        label: 	'Краткосрочни вземания и заеми от банки и други финансови институции', 
                        show: true
                    },
                    { 
                        id: 522, 
                        label: 	'Краткосрочни вземания и заеми от нефинансови предприятия и други клиенти', 
                        show: true
                    },
                    { 
                        id: 523, 
                        label: 	'Краткосрочни вземания и заеми, заложени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 524, 
                        label: 	'Просрочени краткосрочни вземания и заеми', 
                        show: true
                    },
                    { 
                        id: 529, 
                        label: 	'Други краткосрочни вземания и заеми', 
                        show: true
                    },
                ],
            },
            { 
                id: 60, 
                label: 	'Разходи по икономически елементи', 
                show: true,
                children: [
                    { 
                        id: 601, 
                        label: 	'Разходи за материали', 
                        show: true
                    },
                    { 
                        id: 602, 
                        label: 	'Разходи за външни услуги', 
                        show: true
                    },
                    { 
                        id: 603, 
                        label: 	'Разходи за амортизации', 
                        show: true
                    },
                    { 
                        id: 604, 
                        label: 	'Разходи за заплати (възнаграждения)', 
                        show: true
                    },
                    { 
                        id: 605, 
                        label: 	'Разходи за осигуровки', 
                        show: true
                    },
                    { 
                        id: 606, 
                        label: 	'Разходи за данъци, такси и др.подобни плащания', 
                        show: true
                    },
                    { 
                        id: 607, 
                        label: 	'Разходи за провизии', 
                        show: true
                    },
                    { 
                        id: 608, 
                        label: 	'Разходи от последваща оценка на активи', 
                        show: true
                    },
                    { 
                        id: 609, 
                        label: 	'Други разходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 61, 
                label: 	'Разходи за дейността', 
                show: true,
                children: [
                    { 
                        id: 611, 
                        label: 	'Разходи за основна дейност', 
                        show: true
                    },
                    { 
                        id: 612, 
                        label: 	'Разходи за спомагателна дейност', 
                        show: true
                    },
                    { 
                        id: 613, 
                        label: 	'Разходи за придобиване на дълготрайни активи', 
                        show: true
                    },
                    { 
                        id: 614, 
                        label: 	'Административни разходи', 
                        show: true
                    },
                    { 
                        id: 615, 
                        label: 	'Разходи за продажба', 
                        show: true
                    },
                    { 
                        id: 616, 
                        label: 	'Разходи за ликвидация на ДМА', 
                        show: true
                    },
                    { 
                        id: 617, 
                        label: 	'Разходи по дейности в предприятия с нестопанска цел', 
                        show: true
                    },
                    { 
                        id: 618, 
                        label: 	'Разходи при ликвидация и несъстоятелност', 
                        show: true
                    },
                ],
            },
            { 
                id: 62, 
                label: 	'Финансови разходи', 
                show: true,
                children: [
                    { 
                        id: 621, 
                        label: 	'Разходи за лихви', 
                        show: true
                    },
                    { 
                        id: 622, 
                        label: 	'Разходи за провизиране на рискови активи', 
                        show: true
                    },
                    { 
                        id: 623, 
                        label: 	'Разходи по операции с финансови активи и инструменти', 
                        show: true
                    },
                    { 
                        id: 624, 
                        label: 	'Разходи по валутни операции', 
                        show: true
                    },
                    { 
                        id: 625, 
                        label: 	'Разходи от последващи оценки на финансови активи и инструменти', 
                        show: true
                    },
                    { 
                        id: 629, 
                        label: 	'Други финансови разходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 63, 
                label: 	'Загуба (разходи) от обезценка', 
                show: true,
                children: [
                    { 
                        id: 631, 
                        label: 	'Загуба (разходи) от обезценка', 
                        show: true
                    },
                ],
            },
            { 
                id: 65, 
                label: 	'Разходи за бъдещи периоди', 
                show: true,
                children: [
                    { 
                        id: 651, 
                        label: 	'Нефинансови разходи за бъдещи периоди', 
                        show: true
                    },
                    { 
                        id: 652, 
                        label: 	'Финансови разходи за бъдещи периоди', 
                        show: true
                    },
                ],
            },
            { 
                id: 66, 
                label: 	'Разходи по пряко застраховане', 
                show: true,
                children: [
                    { 
                        id: 661, 
                        label: 	'Разходи за суми по застраховането', 
                        show: true
                    },
                    { 
                        id: 662, 
                        label: 	'Разходи за участие в резултата', 
                        show: true
                    },
                    { 
                        id: 663, 
                        label: 	'Разходи за ликвидация', 
                        show: true
                    },
                    { 
                        id: 664, 
                        label: 	'Разходи за комисиони по застраховането', 
                        show: true
                    },
                    { 
                        id: 665, 
                        label: 	'Разходи за резерви по застраховането', 
                        show: true
                    },
                    { 
                        id: 669, 
                        label: 	'Други разходи по пряко застраховане', 
                        show: true
                    },
                ],
            },
            { 
                id: 67, 
                label: 	'Разходи по пасивно презастраховане', 
                show: true,
                children: [
                    { 
                        id: 671, 
                        label: 	'Разходи за отстъпени премии на презастрахователи', 
                        show: true
                    },
                    { 
                        id: 672, 
                        label: 	'Разходи за освободени резерви по пасивно презастраховане', 
                        show: true
                    },
                    { 
                        id: 679, 
                        label: 	'Други разходи по пасивно презастраховане', 
                        show: true
                    },
                ],
            },
            { 
                id: 68, 
                label: 	'Разходи по активно презастраховане', 
                show: true,
                children: [
                    { 
                        id: 681, 
                        label: 	'Разходи за застрахователни обезщетения', 
                        show: true
                    },
                    { 
                        id: 682, 
                        label: 	'Разходи за участие в резултата', 
                        show: true
                    },
                    { 
                        id: 683, 
                        label: 	'Разходи за участие в ликвидацията', 
                        show: true
                    },
                    { 
                        id: 684, 
                        label: 	'Разходи за отстъпени презастрахователни комисионни и такси', 
                        show: true
                    },
                    { 
                        id: 685, 
                        label: 	'Разходи по заделени резерви по активно презастраховане', 
                        show: true
                    },
                    { 
                        id: 689, 
                        label: 	'Други разходи по активно презастраховане', 
                        show: true
                    },
                ],
            },
            { 
                id: 69, 
                label: 	'Извънредни разходи', 
                show: true,
                children: [
                    { 
                        id: 691, 
                        label: 	'Извънредни разходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 70, 
                label: 	'Приходи от продажби', 
                show: true,
                children: [
                    { 
                        id: 701, 
                        label: 	'Приходи от продажби на продукция', 
                        show: true
                    },
                    { 
                        id: 702, 
                        label: 	'Приходи от продажби на стоки', 
                        show: true
                    },
                    { 
                        id: 703, 
                        label: 	'Приходи от продажби на услуги', 
                        show: true
                    },
                    { 
                        id: 704, 
                        label: 	'Приходи от финансирания', 
                        show: true
                    },
                    { 
                        id: 705, 
                        label: 	'Приходи от продажби на дълготрайни активи', 
                        show: true
                    },
                    { 
                        id: 706, 
                        label: 	'Приходи от продажби на материали', 
                        show: true
                    },
                    { 
                        id: 707, 
                        label: 	'Приходи от ликвидация и несъстоятелност', 
                        show: true
                    },
                    { 
                        id: 709, 
                        label: 	'Приходи от други продажби', 
                        show: true
                    },
                ],
            },
            { 
                id: 71, 
                label: 	'Приходи в предприятия с нестопанска цел', 
                show: true,
                children: [
                    { 
                        id: 711, 
                        label: 	'Приходи от регламентирана дейност', 
                        show: true
                    },
                    { 
                        id: 712, 
                        label: 	'Приходи от членски внос', 
                        show: true
                    },
                    { 
                        id: 713, 
                        label: 	'Приходи от финансирания на програми', 
                        show: true
                    },
                    { 
                        id: 714, 
                        label: 	'Приходи от правителствени дарения', 
                        show: true
                    },
                    { 
                        id: 715, 
                        label: 	'Приходи от други дарения', 
                        show: true
                    },
                    { 
                        id: 719, 
                        label: 	'Други приходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 72, 
                label: 	'Финансови приходи', 
                show: true,
                children: [
                    { 
                        id: 721, 
                        label: 	'Приходи от лихви', 
                        show: true
                    },
                    { 
                        id: 722, 
                        label: 	'Приходи от съучастия', 
                        show: true
                    },
                    { 
                        id: 723, 
                        label: 	'Приходи от операции с финансови активи и инструменти', 
                        show: true
                    },
                    { 
                        id: 724, 
                        label: 	'Приходи от валутни операции', 
                        show: true
                    },
                    { 
                        id: 725, 
                        label: 	'Приходи от последващи оценки на финансови активи и инструменти', 
                        show: true
                    },
                    { 
                        id: 726, 
                        label: 	'Приходи от реинтегрирани провизии', 
                        show: true
                    },
                    { 
                        id: 729, 
                        label: 	'Други финансови приходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 73, 
                label: 	'Приходи от възстановени загуби от обезценка', 
                show: true,
                children: [
                    { 
                        id: 731, 
                        label: 	'Приходи от възстановени загуби от обезценка', 
                        show: true
                    },
                ],
            },
            { 
                id: 75, 
                label: 	'Приходи за бъдещи периоди и финансирания', 
                show: true,
                children: [
                    { 
                        id: 751, 
                        label: 	'Нефинансови приходи за бъдещи периоди', 
                        show: true
                    },
                    { 
                        id: 752, 
                        label: 	'Финансови приходи за бъдещи периоди', 
                        show: true
                    },
                    { 
                        id: 753, 
                        label: 	'Финансиране за дълготрайни активи', 
                        show: true
                    },
                    { 
                        id: 754, 
                        label: 	'Финансиране на текущата дейност', 
                        show: true
                    },
                ],
            },
            { 
                id: 76, 
                label: 	'Приходи по пряко застраховане', 
                show: true,
                children: [
                    { 
                        id: 761, 
                        label: 	'Приходи от премии по застраховането', 
                        show: true
                    },
                    { 
                        id: 764, 
                        label: 	'Приходи от комисиони и такси', 
                        show: true
                    },
                    { 
                        id: 765, 
                        label: 	'Приходи по застраховане от минали години', 
                        show: true
                    },
                    { 
                        id: 766, 
                        label: 	'Приходи от регреси', 
                        show: true
                    },
                    { 
                        id: 768, 
                        label: 	'Приходи от освободени резерви', 
                        show: true
                    },
                    { 
                        id: 769, 
                        label: 	'Други застрахователни приходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 77, 
                label: 	'Приходи от пасивно презастраховане', 
                show: true,
                children: [
                    { 
                        id: 771, 
                        label: 	'Приходи от получени обезщетения от презастрахователи', 
                        show: true
                    },
                    { 
                        id: 772, 
                        label: 	'Приходи от участие в резултата на презастрахователи', 
                        show: true
                    },
                    { 
                        id: 773, 
                        label: 	'Приходи от комисиони от презастрахователи', 
                        show: true
                    },
                    { 
                        id: 774, 
                        label: 	'Приходи от пасивно презастраховане от минали години', 
                        show: true
                    },
                    { 
                        id: 775, 
                        label: 	'Приходи от резерви за презастраховане', 
                        show: true
                    },
                    { 
                        id: 779, 
                        label: 	'Други приходи по пасивно презастраховане', 
                        show: true
                    },
                ],
            },
            { 
                id: 78, 
                label: 	'Приходи по активно презастраховане', 
                show: true,
                children: [
                    { 
                        id: 781, 
                        label: 	'Приходи от премии по презастраховане', 
                        show: true
                    },
                    { 
                        id: 782, 
                        label: 	'Приходи от активно презастраховане от минали години', 
                        show: true
                    },
                    { 
                        id: 783, 
                        label: 	'Приходи от регреси по активно презастраховане', 
                        show: true
                    },
                    { 
                        id: 784, 
                        label: 	'Приходи от резерви по активно презастраховане', 
                        show: true
                    },
                    { 
                        id: 789, 
                        label: 	'Други приходи от активно презастраховане', 
                        show: true
                    },
                ],
            },
            { 
                id: 79, 
                label: 	'Извънредни приходи', 
                show: true,
                children: [
                    { 
                        id: 791, 
                        label: 	'Извънредни приходи', 
                        show: true
                    },
                ],
            },
            { 
                id: 91, 
                label: 	'Чужди материални и нематериални активи', 
                show: true,
                children: [
                    { 
                        id: 911, 
                        label: 	'Чужди МА И НА, предоставени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 912, 
                        label: 	'Наети чужди активи', 
                        show: true
                    },
                    { 
                        id: 913, 
                        label: 	'Чужди материални активи, получени по консигнационен договор', 
                        show: true
                    },
                    { 
                        id: 914, 
                        label: 	'Документи под особена отчетност', 
                        show: true
                    },
                    { 
                        id: 915, 
                        label: 	'Нефинансови ценности, приети на съхранение', 
                        show: true
                    },
                ],
            },
            { 
                id: 92, 
                label: 	'Чужди финансови активи', 
                show: true,
                children: [
                    { 
                        id: 921, 
                        label: 	'Чужди финансови активи, предоставени като обезпечение', 
                        show: true
                    },
                    { 
                        id: 922, 
                        label: 	'Заложени полици', 
                        show: true
                    },
                    { 
                        id: 923, 
                        label: 	'Разни емисии за пускане в обръщение', 
                        show: true
                    },
                    { 
                        id: 924, 
                        label: 	'Чужди финансови активи, държани от името на клиенти', 
                        show: true
                    },
                    { 
                        id: 925, 
                        label: 	'Поети задължения по договори', 
                        show: true
                    },
                ],
            },
            { 
                id: 93, 
                label: 	'Дебитори по условни вземания', 
                show: true,
                children: [
                    { 
                        id: 931, 
                        label: 	'Вземания по предоставени кредити по междудържавни спогодби', 
                        show: true
                    },
                    { 
                        id: 932, 
                        label: 	'Отписани непогасени вземания', 
                        show: true
                    },
                    { 
                        id: 933, 
                        label: 	'Дебитори по операции за инкасиране', 
                        show: true
                    },
                    { 
                        id: 934, 
                        label: 	'Вземания по дериватни сделки', 
                        show: true
                    },
                ],
            },
            { 
                id: 94, 
                label: 	'Условни активи', 
                show: true,
                children: [
                    { 
                        id: 941, 
                        label: 	'Вземания по спот операции', 
                        show: true
                    },
                    { 
                        id: 942, 
                        label: 	'Отписани непогасени вземания в банките', 
                        show: true
                    },
                    { 
                        id: 943, 
                        label: 	'Дебитори по операции за инкасиране в банките', 
                        show: true
                    },
                    { 
                        id: 944, 
                        label: 	'Условни вземания по други банкови операции', 
                        show: true
                    },
                ],
            },
            { 
                id: 95, 
                label: 	'Кредитори по условни вземания', 
                show: true,
                children: [
                    { 
                        id: 951, 
                        label: 	'Гаранции и други подобни непредвидени задължения', 
                        show: true
                    },
                    { 
                        id: 952, 
                        label: 	'Неотменяеми ангажименти', 
                        show: true
                    },
                    { 
                        id: 953, 
                        label: 	'Отменяеми ангажименти', 
                        show: true
                    },
                    { 
                        id: 954, 
                        label: 	'Задължения по спот-операции', 
                        show: true
                    },
                    { 
                        id: 955, 
                        label: 	'Задължения по дериватни сделки', 
                        show: true
                    },
                    { 
                        id: 959, 
                        label: 	'Условни задължения по други банкови операции', 
                        show: true
                    },
                ],
            },
            { 
                id: 96, 
                label: 	'Собствени активи, невключени в стоп.оборот', 
                show: true,
                children: [
                    { 
                        id: 961, 
                        label: 	'Резервна каса', 
                        show: true
                    },
                    { 
                        id: 962, 
                        label: 	'Активи в употреба, отчетени като разход', 
                        show: true
                    },
                ],
            },
            { 
                id: 97, 
                label: 	'Собствени пасиви, невключени в стоп.оборот', 
                show: true,
                children: [
                    { 
                        id: 971, 
                        label: 	'Емисионен резерв', 
                        show: true
                    },
                    { 
                        id: 972, 
                        label: 	'Собствени емисии извън обръщение с изтекъл срок за обмяна', 
                        show: true
                    },
                ],
            },
            { 
                id: 98, 
                label: 	'Разни сметки за условни активи', 
                show: true,
                children: [
                    { 
                        id: 981, 
                        label: 	'Сметка по резервите', 
                        show: true
                    },
                    { 
                        id: 982, 
                        label: 	'Сметки по изискуеми обезпечения', 
                        show: true
                    },
                    { 
                        id: 983, 
                        label: 	'Ангажименти по разрешени кредити', 
                        show: true
                    },
                    { 
                        id: 984, 
                        label: 	'Разни статистически сметки', 
                        show: true
                    },
                    { 
                        id: 989, 
                        label: 	'Други сметки за условни активи', 
                        show: true
                    },
                ],
            },
            { 
                id: 99, 
                label: 	'Разни сметки за условни пасиви', 
                show: true,
                children: [
                    { 
                        id: 999, 
                        label: 	'Други сметки за условни пасиви', 
                        show: true
                    },
                ],
            },
        ],
    }
];

const generateList = (list) => {
    let listItems = list.map(item => {
        let children = '';
        if (item.children) {
            if (item.children.length > 0) {
                children = generateList(item.children);
            }
        }
        return (<li className={item.show ? 'd-block' : 'd-none'} key={item.id}>
            <a href={`#${item.id}`}>{item.id}. { item.label }</a>
            { children !== '' ? (children) : '' }
        </li>);
    });
    return (<ul className="financialAccountingList pl-3">{ listItems }</ul>);
};
const printGeneratedList = generateList(financialAccounts);

const generateTable = (row) => {
    let rowItems = row.map(item => {
        let children = '';
        if (item.children) {
            if (item.children.length > 0) {
                children = generateTable(item.children);
            }
        }
        return (
            <>
            <tr className={item.id.toString().length === 2 ? 'bg-tdhead' : ''}>
            <th id={`${item.id}`} scope="row" className="text-center">{item.id}</th>
            <td>{ item.label }</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            </tr>
            { children !== '' ? (children) : '' }
            </>
            );
    });
    return (rowItems);
};
const printGeneratedTable = generateTable(financialAccounts);

function AccountingChartNav() {
    return (
        <div className="col-4 accountingChartContainer bg-light">

            <form className="form-inline col-12 p-0 m-0 border-bottom">
                <div className="btn btn-transparent mt-1" data-tip data-for="openAllListChildren">
                    <i className="fas fa-folder-open text-primary" /> 
                </div>
                <ReactTooltip id="openAllListChildren" place="right" type="info">
                    <span>Отвори всички</span>
                </ReactTooltip>

                <div className="btn btn-transparent mt-1" data-tip data-for="closeAllListChildren">
                    <i className="fas fa-folder text-primary" /> 
                </div>
                <ReactTooltip id="closeAllListChildren" place="right" type="info">
                    <span>Затвори всички</span>
                </ReactTooltip>

                <div className="btn btn-transparent mt-1" data-tip data-for="tableExportExcel">
                    <i className="fas fa-file-excel text-primary" /> 
                </div>
                <ReactTooltip id="tableExportExcel" place="bottom" type="info">
                    <span>Експорт към Excel</span>
                </ReactTooltip>
            </form>

            { printGeneratedList }
        </div>
    );
}

function AccountingChartList() {
    return (
        <div className="col-8 accountingChartContainer bg-light">

            <form className="form-inline col-12 p-0 m-0">
                <div className="group row col-6  p-0 m-0">
                <div className="btn btn-transparent mt-1" data-tip data-for="tableSettingPrint">
                    <i className="fas fa-cog text-primary" /> 
                </div>
                <ReactTooltip id="tableSettingPrint" place="bottom" type="info">
                    <span>Настройка на отпечатването</span>
                </ReactTooltip>

                <div className="btn btn-transparent mt-1" data-tip data-for="tableSettingText">
                    <i className="fas fa-file-alt text-primary" /> 
                </div>
                <ReactTooltip id="tableSettingText" place="bottom" type="info">
                    <span>Текстове към справката</span>
                </ReactTooltip>

                <div className="btn btn-transparent mt-1" data-tip data-for="tablePrint">
                    <i className="fas fa-print text-primary" /> 
                </div>
                <ReactTooltip id="tablePrint" place="bottom" type="info">
                    <span>Печат на таблицата</span>
                </ReactTooltip>

                <div className="btn btn-transparent mt-1" data-tip data-for="tableExportExcel">
                    <i className="fas fa-file-excel text-primary" /> 
                </div>
                <ReactTooltip id="tableExportExcel" place="bottom" type="info">
                    <span>Експорт към Excel</span>
                </ReactTooltip>
                </div>
                <div className="col-6 p-0 m-0 d-flex flex-row justify-content-end">
                    <button data-tip data-for="showFilterByPeriod" type="button" className="btn btn-transparent mr-2">
                        <i className="fab fa-elementor text-primary" />
                    </button>
                    <ReactTooltip id="showFilterByPeriod" place="bottom" type="info">
                    <span>Справка за период</span>
                    </ReactTooltip>
                    
                    <label className="mr-2">Към дата: </label>
                    <input type="date" className="form-control" />
                </div>
            </form>

            <table className="table table-bordered">
            <thead className="text-center">
                <tr>
                <th scope="col" colSpan="1">№ на сметка</th>
                <th scope="col" colSpan="1">име на сметката</th>
                <th scope="col" colSpan="2">НАЧАЛНО САЛДО</th>
                <th scope="col" colSpan="2">ОБОРОТИ</th>
                <th scope="col" colSpan="2">ОБОРОТИ+НАЧ.С.</th>
                <th scope="col" colSpan="2">КРАЙНО САЛДО</th>
                </tr>
                <tr>
                <th scope="col" colSpan="1" style={{'width': '6%'}}></th>
                <th scope="col" colSpan="1" style={{'width': '46%'}}></th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>дебит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>кредит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>дебит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>кредит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>дебит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>кредит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>дебит</th>
                <th scope="col" colSpan="1" style={{'width': '6%'}}>кредит</th>
                </tr>
            </thead>
            <tbody>
                { printGeneratedTable }
            </tbody>
            </table>
        </div>
    );
}

export default function AccountingChart() {
    // Breadcrumbs navigation data
    let breadcrumbs = [
        {
            address: '/',
            label: 'LINBRIX',
            active: false,
        },
        {
            address: '/accounting',
            label: 'Счетоводство и финанси',
            active: false,
        },
        {
            address: '/accounting/chart',
            label: 'Сметкоплан',
            active: true,
        }
    ];

    return (
        <>
        <Breadcrumbs data={ breadcrumbs } />
        <AccountingChartNavbar />

        <div className="container-fluid">
            <div className="row">
                <AccountingChartNav />
                <AccountingChartList />
            </div>
        </div>
        </>
    );
}