import React from 'react';
import ReactTooltip from 'react-tooltip';
import AddUserModal from './AddUserModal';
import AddDocumentTaskModal from './AddDocumentTaskModal';

export default function DetailsBlock() {
    const [ valueAddUserModal, setAddUserModal ] = React.useState(false);
    const openAddUserModal = () => setAddUserModal(true);
    const closeAddUserModal = () => setAddUserModal(false);

    const [ valueAddDocumentTaskModal, setAddDocumentTaskModal ] = React.useState(false);
    const openAddDocumentTaskModal = () => setAddDocumentTaskModal(true);
    const closeAddDocumentTaskModal = () => setAddDocumentTaskModal(false);

    return (
        <>
        <ul className="nav nav-pills bg-white" id="pills-tab" role="tablist">
            <li className="nav-item">
                <a className="nav-link rounded-sm active" 
                id="pills-accounting-tab" data-toggle="pill" 
                href="#pills-accounting" role="tab" 
                aria-controls="pills-accounting" 
                aria-selected="true">
                    Осчетоводяване
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link rounded-sm" 
                id="pills-details-tab" data-toggle="pill" 
                href="#pills-details" role="tab" 
                aria-controls="pills-details" 
                aria-selected="false">
                    Друга информация
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link rounded-sm" 
                id="pills-team-tab" data-toggle="pill" 
                href="#pills-team" role="tab" 
                aria-controls="pills-team" 
                aria-selected="false">
                    Екип и задачи
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link rounded-sm" 
                id="pills-files-tab" data-toggle="pill" 
                href="#pills-files" role="tab" 
                aria-controls="pills-files" 
                aria-selected="false">
                    Прикачени документи
                </a>
            </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-accounting" role="tabpanel" aria-labelledby="pills-accounting-tab">
            <table class="table table-bordered">
                <thead className="thead-light">
                    <tr className="text-center">
                    <th scope="col" className="col-1">#</th>
                    <th scope="col">дебит</th>
                    <th scope="col">салдо</th>
                    <th scope="col">кредит</th>
                    <th scope="col">салдо</th>
                    <th scope="col">сума</th>
                    <th scope="col" className="col-5">забележка</th>
                    <th scope="col">дата</th>
                    <th scope="col">индекс</th>
                    <th scope="col">групи</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">
                        <i className="fas fa-arrows-alt" />
                        <div className="btn-group m-0 p-0">
                            <button type="button" className="btn p-0" style={{ 'lineHeight': '0' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-cog text-primary mb-1" />
                            </button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="">Нов ред</a>
                                <a className="dropdown-item" href="">Копиране</a>
                                <a className="dropdown-item text-danger" href="">Изтриване</a>
                            </div>
                        </div>
                        1
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                    <tr>
                    <th scope="row">
                        <i className="fas fa-arrows-alt" />
                        <div className="btn-group m-0 p-0">
                            <button type="button" className="btn p-0" style={{ 'lineHeight': '0' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-cog text-primary mb-1" />
                            </button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="">Нов ред</a>
                                <a className="dropdown-item" href="">Копиране</a>
                                <a className="dropdown-item text-danger" href="">Изтриване</a>
                            </div>
                        </div>
                        2
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                </tbody>
            </table>
            </div>

            <div className="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                <form>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Описание за дневника по ЗДДС</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Подробно описание ( <input type="checkbox" className="form-check-input d-inline ml-1" id="detailedInformationShowOnPrint" /> <label className="form-check-label d-inline ml-4 font-italic text-secondary" htmlFor="detailedInformationShowOnPrint">Покажи при печат</label> )</label>
                        <textarea className="form-control mb-3" id="accountingDetailsTabTextArea" rows="1"></textarea>
                    </div>
                </form>
            </div>

            <div className="tab-pane fade" id="pills-team" role="tabpanel" aria-labelledby="pills-team-tab">
                <form>
                    <div className="form-group">
                    <table className="table table-hover table-bordered">
                        <thead className="thead-light">
                            <tr>
                            <th scope="col" style={{'width': '22px'}}>
                                <button type="button" data-tip data-for="showAddUserRightsTooltip" className="btn text-primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        openAddUserModal();
                                    }}>
                                    <i className="fas fa-plus" />
                                </button>
                                <ReactTooltip id="showAddUserRightsTooltip" place="top" type="info">
                                    <span>Добавяне на нов потребител</span>
                                </ReactTooltip>
                            </th>
                            <th scope="col">Име на потребителя</th>
                            <th scope="col">Длъжност</th>
                            <th scope="col">Фирма</th>
                            <th scope="col">Офис</th>
                            <th scope="col">E-Mail</th>
                            <th scope="col">Права по документа</th>
                            <th scope="col">Уведомления по документа</th>
                            <th scope="col">Забележка</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" className="text-center">
                                    <div className="btn-group m-0 p-0">
                                        <button type="button" className="btn p-0 m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-cog text-primary mb-1" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="#">Преглед на потребител</a>
                                            <a className="dropdown-item" href="#" onClick={openAddDocumentTaskModal}>Създаване на задача</a>
                                            <a className="dropdown-item disabled" href="#">Изтриване</a>
                                        </div>
                                    </div>
                                </th>
                                <td>Иван Петров</td>
                                <td>Финансов директор</td>
                                <td>ЛИНБРИКС ООД</td>
                                <td>София</td>
                                <td>ivan@LINBRIX.com</td>
                                <td>
                                    <div className="form-group ml-1 my-0 py-0 row form-check">
                                        <div style={{'width': '30px'}} className="d-inline-block ml-1">
                                            <input type="checkbox" defaultChecked={true} disabled={true} className="form-check-input" id="team-rights-preview" />
                                            <label className="form-check-label" htmlFor="team-rights-preview" data-tip data-for="showUserRightsSearchTooltip"> <i className="fas fa-search" /> </label>
                                            <ReactTooltip id="showUserRightsSearchTooltip" place="top" type="info">
                                                <span>Преглед</span>
                                            </ReactTooltip>
                                        </div>
                                        
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" defaultChecked={true} disabled={true} className="form-check-input" id="team-rights-edit" />
                                            <label className="form-check-label" htmlFor="team-rights-edit" data-tip data-for="showUserRightsEditTooltip"> <i className="fas fa-pen" /> </label>
                                            <ReactTooltip id="showUserRightsEditTooltip" place="top" type="info">
                                                <span>Редакция</span>
                                            </ReactTooltip>
                                        </div>
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" defaultChecked={true} disabled={true} className="form-check-input" id="team-rights-delete" />
                                            <label className="form-check-label" htmlFor="team-rights-delete" data-tip data-for="showUserRightsDeleteTooltip"> <i className="fas fa-trash" /> </label>
                                            <ReactTooltip id="showUserRightsDeleteTooltip" place="top" type="info">
                                                <span>Изтриване</span>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group ml-1 my-0 py-0 row form-check">
                                        <div style={{'width': '30px'}} className="d-inline-block ml-1">
                                            <input type="checkbox" className="form-check-input" id="team-rights-preview" />
                                            <label className="form-check-label" htmlFor="team-rights-preview" data-tip data-for="showUserNotifyCreateTooltip"> <i className="fas fa-file" /> </label>
                                            <ReactTooltip id="showUserNotifyCreateTooltip" place="top" type="info">
                                                <span>При създаване</span>
                                            </ReactTooltip>
                                        </div>
                                        
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" className="form-check-input" id="team-rights-edit" />
                                            <label className="form-check-label" htmlFor="team-rights-edit" data-tip data-for="showUserNotifyEditTooltip"> <i className="fas fa-pen" /> </label>
                                            <ReactTooltip id="showUserNotifyEditTooltip" place="top" type="info">
                                                <span>При редакция</span>
                                            </ReactTooltip>
                                        </div>
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" className="form-check-input" id="team-rights-delete" />
                                            <label className="form-check-label" htmlFor="team-rights-delete" data-tip data-for="showUserNotifyDeleteTooltip"> <i className="fas fa-trash" /> </label>
                                            <ReactTooltip id="showUserNotifyDeleteTooltip" place="top" type="info">
                                                <span>При изтриване</span>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {/* Delete this row */}

                            <tr>
                                <th scope="row" className="text-center">
                                    <div className="btn-group m-0 p-0">
                                        <button type="button" className="btn p-0 m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-cog text-primary mb-1" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="#">Преглед на потребител</a>
                                            <a className="dropdown-item" href="#" onClick={openAddDocumentTaskModal}>Създаване на задача</a>
                                            <a className="dropdown-item disabled" href="#">Изтриване</a>
                                        </div>
                                    </div>
                                </th>
                                <td>Иван Петров</td>
                                <td>Външен потребител</td>
                                <td>Контрагент 1 ООД</td>
                                <td>София</td>
                                <td>ivanpetrov@mail.bg</td>
                                <td>
                                    <div className="form-group ml-1 my-0 py-0 row form-check">
                                        <div style={{'width': '30px'}} className="d-inline-block ml-1">
                                            <input type="checkbox" defaultChecked={true} disabled={true} className="form-check-input" id="team-rights-preview" />
                                            <label className="form-check-label" htmlFor="team-rights-preview" data-tip data-for="showUserRightsSearchTooltip"> <i className="fas fa-search" /> </label>
                                            <ReactTooltip id="showUserRightsSearchTooltip" place="top" type="info">
                                                <span>Преглед</span>
                                            </ReactTooltip>
                                        </div>
                                        
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" defaultChecked={false} disabled={true} className="form-check-input" id="team-rights-edit" />
                                            <label className="form-check-label" htmlFor="team-rights-edit" data-tip data-for="showUserRightsEditTooltip"> <i className="fas fa-pen" /> </label>
                                            <ReactTooltip id="showUserRightsEditTooltip" place="top" type="info">
                                                <span>Редакция</span>
                                            </ReactTooltip>
                                        </div>
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" defaultChecked={false} disabled={true} className="form-check-input" id="team-rights-delete" />
                                            <label className="form-check-label" htmlFor="team-rights-delete" data-tip data-for="showUserRightsDeleteTooltip"> <i className="fas fa-trash" /> </label>
                                            <ReactTooltip id="showUserRightsDeleteTooltip" place="top" type="info">
                                                <span>Изтриване</span>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group ml-1 my-0 py-0 row form-check">
                                        <div style={{'width': '30px'}} className="d-inline-block ml-1">
                                            <input type="checkbox" className="form-check-input" defaultChecked={true} id="team-rights-preview" />
                                            <label className="form-check-label" htmlFor="team-rights-preview" data-tip data-for="showUserNotifyCreateTooltip"> <i className="fas fa-file" /> </label>
                                            <ReactTooltip id="showUserNotifyCreateTooltip" place="top" type="info">
                                                <span>При създаване</span>
                                            </ReactTooltip>
                                        </div>
                                        
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" className="form-check-input" id="team-rights-edit" />
                                            <label className="form-check-label" htmlFor="team-rights-edit" data-tip data-for="showUserNotifyEditTooltip"> <i className="fas fa-pen" /> </label>
                                            <ReactTooltip id="showUserNotifyEditTooltip" place="top" type="info">
                                                <span>При редакция</span>
                                            </ReactTooltip>
                                        </div>
                                        <div style={{'width': '30px'}} className="d-inline-block ml-2">
                                            <input type="checkbox" className="form-check-input" id="team-rights-delete" />
                                            <label className="form-check-label" htmlFor="team-rights-delete" data-tip data-for="showUserNotifyDeleteTooltip"> <i className="fas fa-trash" /> </label>
                                            <ReactTooltip id="showUserNotifyDeleteTooltip" place="top" type="info">
                                                <span>При изтриване</span>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a href="">
                                        Задача 2020-04-16/13:00
                                    </a>
                                </td>
                            </tr>

                            {/* Delete this row */}

                        </tbody>
                    </table>
                    </div>
                </form>
            </div>

            <div className="tab-pane fade" id="pills-files" role="tabpanel" aria-labelledby="pills-files-tab">
                    <div className="form-group">
                    <h4>Версии на документа</h4>
                    <table className="table table-bordered table-hover">
                        <thead className="thead-light">
                            <tr>
                            <th scope="col" style={{'width': '300px'}}>Име на документа</th>
                            <th scope="col" style={{'width': '150px'}}>Дата на действието</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Действие</th>
                            <th scope="col">Служител</th>
                            <th scope="col" style={{'width': '100px'}}>Размер</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td><i className="fas fa-file-pdf text-danger ml-2" /> <a href="">7539651483-22042020-invoice.pdf</a></td>
                            <td>22/04/2020 15:00ч.</td>
                            <td>Активен</td>
                            <td>Редакция</td>
                            <td>Иван Петров</td>
                            <td>2.00 MB</td>
                            </tr>
                            <tr>
                            <td><i className="fas fa-file-pdf text-danger ml-2" /> <a href="">7539651482-21042020-invoice.pdf</a></td>
                            <td>21/04/2020 11:00ч.</td>
                            <td>Архивиран</td>
                            <td>Редакция</td>
                            <td>Иван Петров</td>
                            <td>2.00 MB</td>
                            </tr>
                            <tr>
                            <td><i className="fas fa-file-pdf text-danger ml-2" /> <a href="">7539651481-20042020-invoice.pdf</a></td>
                            <td>20/04/2020 12:00ч.</td>
                            <td>Архивиран</td>
                            <td>Създаване</td>
                            <td>Иван Петров</td>
                            <td>2.00 MB</td>
                            </tr>
                        </tbody>
                        </table>

                        <h4>Прикачени документи</h4>
                        <table className="table table-bordered table-hover">
                        <thead className="thead-light">
                            <tr>
                            <th scope="col" style={{'width': '300px'}}>Име на документа</th>
                            <th scope="col" style={{'width': '150px'}}>Дата на добавяне</th>
                            <th scope="col" style={{'width': '100px'}}>Размер</th>
                            <th scope="col">Служител</th>
                            <th scope="col">Бележки</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td><i className="fas fa-file-pdf text-danger ml-2" /> <a href="">7539651480-22042020.pdf</a></td>
                            <td>22/04/2020 15:00ч.</td>
                            <td>2.00 MB</td>
                            <td>Иван Петров</td>
                            <td>Подробни бележки относно документа</td>
                            </tr>
                            <tr>
                            <td><i className="fas fa-file-word text-primary ml-2" /> <a href="">9512364587-22042020.doc</a></td>
                            <td>22/04/2020 13:20ч.</td>
                            <td>1.00 MB</td>
                            <td>Иван Петров</td>
                            <td>Подробни бележки относно документа</td>
                            </tr>
                            <tr>
                            <td><i className="fas fa-file-excel text-success ml-2" /> <a href="">2135984756-22042020.xls</a></td>
                            <td>22/04/2020 11:05ч.</td>
                            <td>3.00 MB</td>
                            <td>Иван Петров</td>
                            <td>Подробни бележки относно документа</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    
                <form className="my-2">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Директория на документа</label>
                        <div className="col-sm-10">
                            <input type="text" disabled={true} defaultValue={`/root/LINBRIX/erp/invoices/7539651480/`} className="form-control" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <AddUserModal 
            value={valueAddUserModal}
            open={openAddUserModal} 
            close={closeAddUserModal} 
        />
        <AddDocumentTaskModal 
            value={valueAddDocumentTaskModal}
            open={openAddDocumentTaskModal} 
            close={closeAddDocumentTaskModal} 
        />
        </>
    );
}