import React from 'react';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';

export default function AccountingChartNavbar() {
    return (
        <>
        <nav className="nav bg-light border-bottom">
            <div className="d-flex">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Настройки
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item dropdown-item-sm" to="#">Импорт</Link>
                        <Link className="dropdown-item dropdown-item-sm" to="#">Архив</Link>
                    </div>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={`#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Екран
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to={`#`}>Всички</Link>
                        <Link className="dropdown-item" to={`#`}>Скрий стриктура на сметките</Link>
                        <Link className="dropdown-item" to={`#`}>Скрий таблица</Link>
                        <Link className="dropdown-item" to={`#`}>Скрий нулевите</Link>
                        <Link className="dropdown-item" to={`#`}>Скрий без салдо</Link>
                    </div>
                </li>
                <li className="nav-item">
                    <Link className="btn btn-transparent mt-1" to={`/accounting/chart`} id="navbarDropdown" aria-haspopup="true" aria-expanded="false" data-tip data-for="showAccountingBooksTooltip">
                        <i className="fas fa-book text-primary" /> 
                    </Link>
                    <ReactTooltip id="showAccountingBooksTooltip" place="bottom" type="info">
                        <span>Счетоводен сметкоплан</span>
                    </ReactTooltip>
                </li>
                <li className="nav-item">
                    <Link className="btn btn-transparent mt-1" to={`#`} id="navbarDropdown" aria-haspopup="true" aria-expanded="false" data-tip data-for="showPNLTooltip">
                        <i className="fas fa-file-invoice-dollar text-primary" /> 
                    </Link>
                    <ReactTooltip id="showPNLTooltip" place="bottom" type="info">
                        <span>ОПР</span>
                    </ReactTooltip>
                </li>
                <li className="nav-item">
                    <Link className="btn btn-transparent mt-1" to={`#`} id="navbarDropdown" aria-haspopup="true" aria-expanded="false" data-tip data-for="showBalanceSheetTooltip">
                        <i className="fas fa-file-contract text-primary" /> 
                    </Link>
                    <ReactTooltip id="showBalanceSheetTooltip" place="bottom" type="info">
                        <span>Баланс</span>
                    </ReactTooltip>
                </li>
            </div>
            <div className="ml-auto mr-2">
                <form className="d-flex mt-1">
                <select className="form-control mr-2">
                    <option disabled>Изберете дружество</option>
                    <option value="1" selected>LINBRIX</option>
                    <option value="2">LINBRIX Инженеринг</option>
                </select>
                </form>
            </div>
        </nav>        
        </>);
}